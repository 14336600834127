import React from "react";
import {translate} from 'react-i18next';

const WeImproveMondays = ({t}) => {
    return (
        <div className="section">
            <div className="container section-2">
                <div className="col">
                    <h4 className="header text-uppercase agilar-yellow">We improve your mondays</h4>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        {/*<div className="group-photo-container">*/}
                        <img src="/images/group-photo.png" className="group-photo"/>
                        {/*</div>*/}
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                        <p className="text-justify">{t('about.section2.line1')}</p>
                        <p className="text-justify">{t('about.section2.line2')}</p>
                    </div>
                </div>
                <div className="col">
                    <p>{t('about.section2.line3')}</p>
                </div>
            </div>
        </div>

    )
}

export default translate('common')(WeImproveMondays);