import React, {useState} from "react"
import {translate} from "react-i18next";
import {withRouter} from "react-router-dom";
import useLocale from "../../../componentLibrary/hooks/useLocale";
import classes from "./NoTrainings.module.css";
import axios from "axios";
import {handleServerError} from "../../../utils/request";

const NoTrainings = ({t, history, category}) => {
    const [country, language] = useLocale();
    const [contact, setContact] = useState({})
    const [disabled, setDisabled] = useState(false)
    const [formClass, setFormClass] = useState('')
    const [flash, setFlash] = useState(null)

    const message1 = t("noTrainings.line1");
    const message2 = t("noTrainings.line2");
    const upcomingCta = t("noTrainings.upcoming_cta");
    const message3 = t("noTrainings.line3");

    const subscribe = (e) => {
        e.preventDefault()
        setDisabled(true)
        axios.post(`${process.env.REACT_APP_API_URL}/api/v2/categories/${category}/subscribe`, {
            subscription: {
                ...contact,
                category: category,
                language: language
            }
        })
            .then(response => {
                setDisabled(false)
                setFormClass(classes.submitted)
                setFlash(t("noTrainings.form.feedback"))
            })
            .catch(error => handleServerError(history, error, country, language))
    }

    const updateField = ({target: {name, value}}) => {
        setContact({
            ...contact,
            [name]: value
        })
        setFormClass(null)
    }

    return (
        <div className={classes.noTraining}>
            <h4>
                <span className={classes.borderContent}>{message1.substring(0, 3)}</span>
                {message1.substring(3, message1.length)}&nbsp;
            </h4>
            {flash &&
                <div className="bg-success flash_msg">{flash}</div>
            }
            <form className={formClass} onSubmit={subscribe}>
                <div className="d-flex justify-content-between">
                    <input className="form-control contact-input mr-4"
                           name="name"
                           onChange={updateField}
                           placeholder={t("noTrainings.form.name")}
                           disabled={disabled}
                           required/>
                    <input className="form-control contact-input mx-4"
                           name="email"
                           type="email"
                           onChange={updateField}
                           placeholder={t("noTrainings.form.email")}
                           disabled={disabled}
                           required/>
                    <button className="btn yellow-btn ml-4" type="submit" disabled={disabled}>
                        {t("noTrainings.form.submit")}
                    </button>
                </div>
            </form>
            <h4>
                {message2}&nbsp;
                <span className="call-to-action"
                      onClick={() => history.push(`/${country}/${language}/courses/upcoming`)}>{upcomingCta}</span>&nbsp;
                {message3}
            </h4>
        </div>
    )
}

export default translate('common')(withRouter(NoTrainings));
