import React, { useEffect } from "react"
import ClientsComponent from "../../components/clients/ClientsComponent";

const ClientsContainer = ({ setActiveMenu }) => {
    useEffect(() => {
        setActiveMenu('clients')
    }, [setActiveMenu])

    return (
        <div style={{paddingTop: '140px'}}>
            <ClientsComponent/>
        </div>
    )
}

export default ClientsContainer
