import React from "react"
import {translate} from "react-i18next";
import {useField} from "formik";
import classes from './InputWrapper.module.css'
import clsx from "clsx";

const NearestOffice = ({t, name, isRequired, containerClass}) => {
    const [field, meta, helpers] = useField(name)

    const onChange = (e) => {
        const val = e.target.value
        helpers.setValue(val)
    }

    const divClass = clsx(containerClass, classes.inputContainer)

    return (
        <div className={divClass}>
            <select className={`form-control select-form select-course`}
                    onChange={onChange}
                    defaultValue="label"
                    name={name}>
                <option disabled value="label">{t('services.registration_contact_us.nearest_office')}</option>
                <optgroup label="Argentina">
                    <option value="buenos_aires">Buenos Aires</option>
                </optgroup>
                <optgroup label="Belgium">
                    <option value="gent">Gent</option>
                </optgroup>
                <optgroup label="Spain">
                    <option value="madrid">Madrid</option>
                </optgroup>
            </select>
            {meta.touched && meta.error ? (
            <div className={classes.validationError} style={{position: 'static'}}>{meta.error}</div>
            ) : null}
        </div>
    )
}

export default translate("common")(NearestOffice)
