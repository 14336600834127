import React from "react";
import {translate} from 'react-i18next';
import Certification from "./certification/Certification";

const Certifications = ({t}) => {
    return (
        <div className="section">
            <div className="container section-3">
                <div className="col">
                    <h4 className="header text-uppercase">{t('about.certifications.title')}</h4>
                </div>
            </div>
            <div className="container-fluid agilar-certifications ">
                <div className="container">
                    <div className="row justify-content-between align-content-center">
                        <Certification name={"rep"}/>
                        <Certification name={"cst"}/>
                        <Certification name={"cec"}/>
                        <Certification name={"foundations"}/>
                        <Certification name={"csp"}/>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default translate('common')(Certifications);