import React from 'react'
import Carousel from "react-bootstrap/Carousel";
import {translate} from "react-i18next";
import {Link} from "react-router-dom";
import useLocale from "../../../componentLibrary/hooks/useLocale";

const HomeSection = (props) => {
  const { t, sliderClass } = props;

  const [country, language] = useLocale();

  return (
    <section className="section">
      <Carousel controls={false}>
        <Carousel.Item className= {sliderClass}>
          <Carousel.Caption className="container intro">
            <div className="col-md-8 offset-md-2 col-sm-12 d-flex flex-column">
              <h1>{ t('home.sliders.improve') }</h1>
              <span>{ t('home.sliders.with') } <span>{ t('home.sliders.slide1') }</span></span>
              <div className="tags">{ t('home.sliders.countries') }</div>

              <Link to={`/${country}/${language}/courses/upcoming`}
                    className="btn yellow-btn text-uppercase home-cta align-self-center">
                {t('home.sliders.cta')}
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  )
}

export default translate('common')(HomeSection);
