import React, {Component} from 'react';
import ReactFullpage from "@fullpage/react-fullpage";
import {translate} from 'react-i18next';
import './index.css'
import HomeSection from "./sections/HomeSection";
import Services from "./sections/services/Services";
import About from "./sections/about/About";
import Clients from "./sections/Clients";
import axios from "axios";
import {handleServerError} from "../../utils/request";
import {withRouter} from "react-router-dom";
import Contact from "./sections/Contact";

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this.props.setActiveMenu('home');
    const country = localStorage.getItem('country')
    const language = localStorage.getItem('language')
    axios.get(`${process.env.REACT_APP_API_URL}/api/v2/get_categories?language=${language}&country=${country}`)
        .then(response => {
          this.setState({categories: response.data})
        })
        .catch(error => handleServerError(this.props.history, error, country, language))
  }

  setSection = (origin, target_section, direction) => {
    const {t} = this.props
    switch (target_section) {
      case 0:
        this.props.setActiveMenu('home')
        document.title = t('home.title.home');
        break;
      case 1:
        this.props.setActiveMenu('services')
        document.title = t('home.title.services');
        break;
      case 2:
        this.props.setActiveMenu('about')
        document.title = "Agilar | "+t('home.who_we_are.who_we_are');
        break;
      case 3:
        this.props.setActiveMenu('clients');
        document.title = "Agilar | "+t('home.clients.header');
        break;
      case 4:
        this.props.setActiveMenu('contact')
        document.title = t('home.title.contact');
        break;
      default:
        break;
    }
  }

  render () {
    const { t } = this.props;
    const country = localStorage.getItem('country') || 'es'
    var default_languages = {}
    default_languages['ar'] = 'es'
    default_languages['es'] = 'es'
    default_languages['be'] = 'en'
    var lang = default_languages[country];
    const i8n_language = default_languages[country];
    const service_tooltip = lang === "en" ? "Services" : "Servicios"
    const contact_tooltip = lang === "en" ? "Contact" : "Contacto"

    const default_images = {}
    // default_languages['uk'] = 'en'
    default_images['ar'] = 'Home_Argentina'
    default_images['es'] = 'Home_Spain'
    default_images['be'] = 'Home_Belgium'

    return (
        <ReactFullpage
          sectionsColor={['#fff', '#fff', '#fff']}
          navigation =  {true}
          fitToSection =  {false}
          navigationPosition =  {'right'}
          navigationTooltips={[ t('home.nav.home'), service_tooltip, "Agilar", t('home.nav.clients'), contact_tooltip]}
          slidesNavigation = {true}
          scrollOverflow = {true}
          anchors={['home', 'services', 'about', 'clients', 'contact']}
          onLeave ={(origin, destination, direction) => { this.setSection(origin, destination.index, direction) }}
          render={({ state, fullpageApi }) => {
            return (
              <div id="fullpage-wrapper">
                <HomeSection sliderClass={this.getSliderClass()}/>
                <Services i8n_language={i8n_language} country={country} categories={this.state.categories}/>
                <About language={i8n_language} country={country}/>
                <Clients/>
                <Contact setActiveMenu={this.props.setActiveMenu} />
              </div>
            );
          }}
        />
    )

  }

  getSliderClass(){
    var cls = "slider-data"
    var country = localStorage.getItem('country') || 'es'
    switch(country) {
      case "ar":
        cls = cls + " argentina"
        break;
      case "es":
        cls = cls + " spain"
        break;
      case "be":
        cls = cls + " belgium"
        break;
      default:
        //
        break;
    }
    return cls;
  }

}
export default translate('common')(withRouter(Home));
