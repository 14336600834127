import React from "react"
import {translate} from "react-i18next";
import Course from "./Course/index";

const CourseList = ({courses, country, language}) => {
  if (!courses) return null;

  return (
    <>
      {courses.map((course, index) => <Course key={index}
                                     country={country}
                                     language={language}
                                     course={course}/>
      )}
    </>
  )
}

export default translate('common')(CourseList);
