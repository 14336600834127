import React from "react"
import CoursePrice from "../../CoursePrice";
import {Link} from "react-router-dom";
import {translate} from "react-i18next";

const CourseBody = ({course, country, language, t}) => {
    return (
        <div className="course-body">
            <div className="col-md-2 course-data">
                <span className="course-description">{t('services.courses_list.trainer')}</span>
                <h6>{course.trainer}</h6>
            </div>
            <div className="col-md-2 course-data">
                <span className="course-description">{t('services.courses_list.location')}</span>
                <h6>{course.city}</h6>
            </div>
            <div className="col-md-2 course-data">
                <span className="course-description">{t('services.courses_list.language')}</span>
                <h6>{t(`services.course_registration.language.${course.language}`)}</h6>
            </div>
            <div className="col-md-2 course-data">
                <span className="course-description">{t('services.courses_list.schedule')}</span>
                <h6>{course.schedule}</h6>
            </div>
            <div className="col-md-2 course-data">
                <span className="course-description">{t('services.courses_list.price')}</span>
                <CoursePrice course={course}/>
            </div>
            <div className="col-md-2 course-data">
                {course.full &&
                <span className="full-warning">{t('services.courses_list.fully_booked')}</span>}
                <Link to={`/${country}/${language}/courses/${course.id}/register`}
                      className="btn yellow-btn btn-training">
                    {course.full ? t('services.courses_list.waiting_list') : t('services.courses_list.register_now')}
                </Link>
            </div>
        </div>
    )
}

export default translate("common")(CourseBody);

