import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './responsive.css';
import './styles/common.css'
import App from './containers/App';
import 'bootstrap/dist/css/bootstrap.css';
import {I18nextProvider} from "react-i18next";
import i18next from 'i18next';

import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import common_ar from "./translations/ar/common.json";
import {BrowserRouter} from "react-router-dom";

i18next.init({
	interpolation: {
		escapeValue: false
	},
	lng: 'en',
	resources: {
		en: {
			common: common_en
		},
		es: {
			common: common_es
		},
		ar: {
			common: common_ar
		},
	},
});



ReactDOM.render(
	<BrowserRouter>
		<I18nextProvider i18n={i18next}>
			<App/>
		</I18nextProvider>
	</BrowserRouter>,
	document.getElementById('root')
)
;
