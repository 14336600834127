import React from 'react'
import {translate} from "react-i18next";
import "./Clients.css"
import {CLIENT_LOGOS} from "../../../utils/Constants";
import {Link} from "react-router-dom";
import useLocale from "../../../componentLibrary/hooks/useLocale";

const Clients = (props) => {
  const {t} = props;
  const [country, language] = useLocale()
  const clients = CLIENT_LOGOS[country];
  const cols = 12 / (clients.length / 2);

  return (
    <section className="section clients" id="section3">
      <div className="container">
        <div className="content">
          <h4 className="header text-uppercase">
              <span className="border-head">{t('home.clients.header').substring(0, 1)}</span>{t('home.clients.header').substring(1, t('home.clients.header').length)}
          </h4>
        </div>
        <div className=" text-justify text-row">
          <p>{t('home.clients.content')}</p>
          <p className="mt-2">{t('home.clients.our_clients')}</p>
        </div>
        <div className=" container">
          <div className="row mt-4">
            {clients.map(client => (
              <div className={`client-image-container col-12 col-md-6 col-lg-${cols} mb-4`} key={client.name}>
                <img className="client-image" src={client.image} alt={client.name}/>
                {/*<div className="overlay">{client.overlayText}</div>*/}
              </div>
            ))}
          </div>
        </div>
        <Link to={`/${country}/${language}/clients`} className="btn yellow-btn text-uppercase">
           { t('home.clients.moreInfo') }
        </Link>
      </div>
    </section>
  )
}

export default translate('common')(Clients);
