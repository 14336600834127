import React from 'react';
import {Link} from 'react-router-dom';
import {translate} from 'react-i18next';
import useLocale from "../../../componentLibrary/hooks/useLocale";
import CourseList from "../CourseList";
import NoTrainings from "../../trainings/NoTrainings";

const CategoryCourses = ({trainings, loading, category, t}) => {
    const [country, language]  = useLocale()

    const coursesExist = loading || (!!trainings && trainings.length > 0)

    return (
        <div className="courses-main">
            <div className="courses-section">
                <div className="container courses-container">
                    <div className="col-md-12">
                        <Link to={`/${country}/${language}/trainings`} className="back_to_all">
                            &#x3c;&nbsp;{t('services.courses_list.back_to_all')}
                        </Link>
                    </div>
                    <div className="col-md-12 courses-row">
                        {coursesExist ?
                            <CourseList courses={trainings}
                                        language={language}
                                        country={country}/> :
                            <NoTrainings category={category}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default translate('common')(CategoryCourses);
