import React, {Component} from 'react';
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import {Link} from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import {Nav} from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import {translate} from 'react-i18next';
import './index.css';
import {withRouter} from 'react-router-dom';
import {COUNTRY_TO_LANGUAGE_MAP} from "../../utils/Constants";

class Header extends Component {

  trainings = () => {
    const {country, language, history} = this.props;
    history.push(`/${country}/${language}/trainings`)
  }

  aboutUs = () => {
    const {country, language, history} = this.props;
    history.push(`/${country}/${language}/who-we-are`)
  }

  render() {
    const countries = {
      ar: 'Argentina',
      es: 'España',
      be: 'Belgium'
    };

    const selected_flag = countries[this.props.country];
    delete countries[this.props.country];

    const ordered_countries = {}
    Object.keys(countries).sort().forEach(function (key) {
      ordered_countries[key] = countries[key];
    });

    let remaining_countries = Object.keys(ordered_countries).map(key => {
      return (
        <a className="dropdown-item" key={key}
           href={`/${key}/${COUNTRY_TO_LANGUAGE_MAP[key]}#home`}>{ordered_countries[key]}</a>
      );
    });

    const {language, country, t} = this.props

    const homeClass = this.props.activeMenu === 'home' ? 'active' : null;
    const serviceClass = this.props.activeMenu === 'services' ? 'active' : null;
    const aboutClass = this.props.activeMenu === 'about' ? 'active' : null;
    const clientsClass = this.props.activeMenu === 'clients' ? 'active' : null;
    const contactClass = this.props.activeMenu === 'contact' ? 'active' : null;
    const upcomingClass = this.props.activeMenu === 'upcoming' ? 'active' : null;

    let blogUrl = process.env.REACT_APP_BLOG_URL
    blogUrl = language === 'en' ? `${blogUrl}/en/agilar-blog` : blogUrl

    return (
      <div>
        <div className="top-navigation">
          <div className="container">
            <Navbar expand="lg">
              <Navbar.Brand>
                <Link to={`/${country}/${language}#home`}
                      className="navbar-brand home_link">
                  <img src={`/images/Agilar-${country}.png`} alt="WWW"/>
                </Link>
              </Navbar.Brand>
              <div className="country-lang">
                <Dropdown className="lang-dd country-dd">
                  <Dropdown.Toggle id="dropdown-basic" className="btn-lang-dd">
                    {selected_flag}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="country-item">
                    {remaining_countries}
                    {/*<a href="https://www.agilar.de/en/" target="_blank" rel="noopener noreferrer"*/}
                    {/*   className="dropdown-item">Germany</a>*/}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="navbarSupportedContent">
                <Nav className="ml-auto">
                  <Nav.Item>
                    <a href={`/${country}/${language}#home`}
                       className={homeClass}>
                      {t('home.nav.home')}
                      <span className="sr-only">(current)</span>
                    </a>
                  </Nav.Item>
                  <Nav.Item>
                    <a href={`/${country}/${language}/courses/upcoming`}
                       className={upcomingClass}>
                      {t('home.nav.upcoming')}
                      <span className="sr-only">(current)</span>
                    </a>
                  </Nav.Item>
                  <Nav.Item className="services-dropdown">
                    <a href={`/${country}/${language}#services`}
                       className={`${serviceClass}`}>
                      {t('home.nav.services')}
                    </a>
                    <span onClick={this.trainings} className="training-link">
                      {t('home.nav.training')}
                    </span>
                  </Nav.Item>
                  <Nav.Item className="services-dropdown">
                    <a href={`/${country}/${language}#about`}
                       className={`${aboutClass}`}>
                      {t('home.nav.agilar')}
                    </a>
                    <span onClick={this.aboutUs} className="training-link">
                      {t('home.nav.about_us')}
                    </span>
                  </Nav.Item>
                  <Nav.Item>
                    <a href={`/${country}/${language}#clients`} className={clientsClass}>
                      {t('home.nav.clients')}
                    </a>
                  </Nav.Item>
                  <Nav.Item>
                    <a href={`/${country}/${language}#contact`} className={contactClass}>
                      {t('home.nav.contact')}
                    </a>
                  </Nav.Item>
                  <Nav.Item>
                    <a href={blogUrl}>BLOG</a>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
              <div className="country-language">
                <Dropdown className="lang-dd country-dd">
                  <Dropdown.Toggle id="dropdown-basic" className="btn-lang-dd">
                    {selected_flag}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="country-item">
                    {remaining_countries}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Navbar>
        </div>
      </div>
  </div>
  )
    ;
  }
}

export default translate('common')(withRouter(Header));
