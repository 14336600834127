import React from "react"
import moment from "moment/min/moment-with-locales"
import {translate} from "react-i18next";
import useLocale from "../../componentLibrary/hooks/useLocale";


const CoursePrice = ({t, course}) => {
    let [country, language] = useLocale()
    language = language === 'ar' ? 'es' : language
    const momentInstance = moment(course.discount_expiration_date); // change the global locale to Spanish
    momentInstance.locale(language)

    const getFormattedPrice = (price) => {
        switch (country) {
            case "es": return `${price} € + IVA`;
            case "ar": return `${price} € + IVA`;
            default: return `${price} € + VAT`;
        }
    };

    const hasDiscount = !!course.discounted_price;
    const hasExpirationDate = !!course.discount_expiration_date

    return (
        <>
            <p className={`course-price ${hasDiscount ? 'discounted pb-0 mb-0' : ''}`}>{getFormattedPrice(course.price)}</p>
            {hasDiscount &&
            <p className="course-price pt-0 mb-0">
                {getFormattedPrice(course.discounted_price)}
                {
                    hasExpirationDate &&
                    <span>&nbsp;{t('services.courses_list.until')} {momentInstance.format("DD/MM")}</span>
                }
            </p>}
        </>
    )
}

export default translate('common')(CoursePrice);
