import React, {Component} from 'react';
import Header from "../components/header/header";
import '../fullpage.css';
import {translate} from 'react-i18next';
import Home from "../components/home/home";
import Trainings from "../components/trainings/trainings";
import CategoryInfo from "../components/trainings/category_info";
import Consulting from "../components/consulting/consulting";
import CourseDetail from "../components/courses/CourseDetail";
import CourseRegistration from "../components/courses/course_registration";
import CourseContact from "../components/courses/CourseContact";
import Contact from "../components/home/sections/Contact";
import Terms from "../components/pages/terms";
import Privacy from "../components/pages/privacy";
import Policy from "../components/pages/policy";
import Legal from "../components/pages/legal";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import CookieBanner from "../components/home/cookie_banner";
import MenuRoute from "../components/MenuRoute";
import NotFound from "../components/pages/NotFound";
import Forbidden from "../components/pages/Forbidden";
import ServerError from "../components/pages/ServerError";
import AboutAgilar from "../components/aboutAgilar/AboutAgilar";
import CourseRegistrationSuccess from "../components/courses/CourseRegistrationSuccess";
import CategoryTrainingsContainer from "./trainings/CategoryTrainingsContainer";
import UpcomingTrainingsContainer from "./trainings/UpcomingTrainingsContainer";
import Landing from "../components/Landing";
import {PREFERRED_COUNTRY_KEY} from "../utils/Constants";
import Newsletter from "../components/pages/Newsletter";
import ClientsContainer from "./clients/ClientsContainer";

class App extends Component {

  state = {
    activeMenu: 'home',
    language: 'en',
    country: 'be'
  }

  setActiveMenu = (activeMenu) => {
    this.setState({activeMenu})
  }

  render () {
    const { i18n } = this.props;
    const {activeMenu} = this.state
    const setActiveMenu = this.setActiveMenu
    return (
        <>
        <Switch>
          <Route path="/:country/:language" render={(localeProps) => {
            const country = localeProps.match.params['country'];
            const language = localeProps.match.params['language'];
            localStorage.setItem('language', language);
            localStorage.setItem('country', country);

            const preferredCountry = localStorage.getItem(PREFERRED_COUNTRY_KEY)
            if (!preferredCountry)
              localStorage.setItem(PREFERRED_COUNTRY_KEY, country)

            const i18nLanguage = country === 'ar' ? 'ar' : language

            if (i18n.language !== i18nLanguage) {
              i18n.changeLanguage(i18nLanguage);
            }
            return (
              <>
                <Header
                  country = {country}
                  language = {language}
                  activeMenu={activeMenu}
                />
              <Switch>
                <MenuRoute exact path={`${localeProps.match.path}`} setActiveMenu={setActiveMenu} component={Home}/>
                <MenuRoute exact path={`${localeProps.match.path}/trainings`} setActiveMenu={setActiveMenu} component={Trainings} />
                <MenuRoute exact path={`${localeProps.match.path}/trainings/:category`} setActiveMenu={setActiveMenu} component={CategoryInfo} />
                <MenuRoute path={`${localeProps.match.path}/consulting`} setActiveMenu={setActiveMenu} component={Consulting} />
                <MenuRoute exact path={`${localeProps.match.path}/courses`} setActiveMenu={setActiveMenu} component={CategoryTrainingsContainer} />
                <MenuRoute exact path={`${localeProps.match.path}/courses/upcoming`} setActiveMenu={setActiveMenu} component={UpcomingTrainingsContainer} />
                <MenuRoute exact path={`${localeProps.match.path}/courses/:id`} setActiveMenu={setActiveMenu} component={CourseDetail} />
                <MenuRoute exact path={`${localeProps.match.path}/courses/:id/register`} setActiveMenu={setActiveMenu} component={CourseRegistration} />
                <MenuRoute exact path={`${localeProps.match.path}/courses/:id/success/`} setActiveMenu={setActiveMenu} component={CourseRegistrationSuccess} />
                <MenuRoute exact path={`${localeProps.match.path}/courses/:id/contact`} setActiveMenu={setActiveMenu} component={CourseContact} />
                <MenuRoute path={`${localeProps.match.path}/contact`} setActiveMenu={setActiveMenu} component={Contact} />
                <MenuRoute path={`${localeProps.match.path}/who-we-are`} setActiveMenu={setActiveMenu} component={AboutAgilar} />
                <MenuRoute path={`${localeProps.match.path}/clients`} setActiveMenu={setActiveMenu} component={ClientsContainer} />
                <Route path={`${localeProps.match.path}/terms`} component={Terms} />
                <Route path={`${localeProps.match.path}/privacy-policy`} component={Privacy} />
                <Route path={`${localeProps.match.path}/cookies-policy`} component={Policy} />
                <Route path={`${localeProps.match.path}/legal-warning`} component={Legal} />
                <Route path={`${localeProps.match.path}/not-found`} exact component={NotFound} />
                <Route path={`${localeProps.match.path}/forbidden`} component={Forbidden} />
                <Route path={`${localeProps.match.path}/server-error`} component={ServerError} />
                <Route path="/" render={() => <Redirect to="/be/en" />}/>
              </Switch>
              </>
            )
          }}
          />
          <Route path="/newsletter" exact component={Newsletter} />
          <Route path="/" component={Landing}/>
        </Switch>
        <CookieBanner />
        </>
    );
  }
}

export default translate('common')(withRouter(App));
