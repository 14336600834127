import React, {useEffect, useState} from "react"
import Addresses from "../../addresses";
import {translate} from "react-i18next";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import InputWrapper from "../../../../componentLibrary/InputWrapper";
import axios from "axios";
import {handleServerError} from "../../../../utils/request";
import useLocale from "../../../../componentLibrary/hooks/useLocale";
import TextAreaWrapper from "../../../../componentLibrary/InputWrapper/TextAreaWrapper";
import {useHistory} from "react-router-dom";
import NearestOffice from "../../../../componentLibrary/InputWrapper/NearestOffice";

const Contact = ({setActiveMenu, t}) => {
    const [flash, setFlash] = useState(null)
    const [country, language] = useLocale()
    const history = useHistory()

    useEffect(() => {
        setActiveMenu('services')
    }, [])

    const onSubmit = (values, {setSubmitting}) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/v2/contact_us`, {
            message: {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                phone: values.phone,
                message: values.message,
                company: values.company,
                nearest_office: values.nearestOffice
            },
            language: language,
        }).then(response => {
            setFlash(response.data.message)
            setSubmitting(false)
        }).catch(error => handleServerError(history, error, country, language))
    }

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        company: "",
        nearestOffice: ""
    }

    const noe = t('register.validation.documentNumber')

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('register.validation.firstName')),
        lastName: Yup.string().required(t('register.validation.lastName')),
        email: Yup.string().email("Please enter a valid email").required(t('register.validation.emailAddress')),
        phone: Yup.string(),
        company: Yup.string().required(t('register.validation.company')),
        nearestOffice: Yup.string(noe).required(noe).test('not-lable', noe, value => {
            return value !== 'label'
        }),
        message: Yup.string().required(t('register.validation.documentNumber')),
    })


    return (
        <section className="section fp-auto-height">
            <div className="contact-form">
                <div className="container">
                    <div className="col-md-8 offset-md-2">
                        {flash &&
                        <div className="bg-success flash_msg">{flash}</div>
                        }
                    </div>
                    <div className="col-md-12">
                        <div className="contact-head">
                            <h6>{t('services.registration_contact_us.get_in_touch')}</h6>
                            <p>{t('services.registration_contact_us.lets_discuss')}</p>
                            <p>{t('services.registration_contact_us.fill_out_form_line')}</p>
                        </div>
                    </div>
                    <div className="col-md-12 contact-data contact_form pt-4">
                        <Formik onSubmit={onSubmit}
                                initialValues={initialValues}
                                validationSchema={validationSchema}>
                            {({isSubmitting}) =>
                                <Form>
                                    <div className="form-row">
                                        <InputWrapper name="firstName"
                                                      label={t('services.registration_contact_us.first_name')}
                                                      containerClass="col-md-4"
                                                      isRequired/>
                                        <InputWrapper name="lastName"
                                                      label={t('services.registration_contact_us.last_name')}
                                                      containerClass="col-md-4"
                                                      isRequired/>
                                        <InputWrapper name="email"
                                                      label={t('services.registration_contact_us.email_address')}
                                                      containerClass="col-md-4"
                                                      isRequired/>
                                    </div>
                                    <div className="form-row">
                                        <InputWrapper name="phone"
                                                      label={t('services.registration_contact_us.phone_no')}
                                                      containerClass="col-md-4"/>
                                        <InputWrapper name="company"
                                                      label={t('services.registration_contact_us.company')}
                                                      containerClass="col-md-4 "
                                                      isRequired/>
                                        <NearestOffice name="nearestOffice"
                                                       containerClass="col-md-4"
                                                       isRequired/>
                                    </div>
                                    <div className="form-row">
                                        <TextAreaWrapper name="message"
                                                         label={t('services.registration_contact_us.how_can_we_help')}
                                                         containerClass="col-md-12"
                                                         isRequired/>
                                    </div>
                                    <button type="submit"
                                            className="btn yellow-btn contact-btn mt-2">
                                        {t('services.courses_list.contact_us')}
                                    </button>
                                </Form>
                            }
                        </Formik>
                    </div>
                </div>
            </div>
            <Addresses/>
        </section>
    )
}

export default translate("common")(Contact)
