import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { translate } from 'react-i18next';

class Legal extends Component {

  componentDidMount(){
    const { t } = this.props;
    document.title = "Agilar | "+ t('home.footer.legal_notice');
  }

  render () {
    const i8n_language = localStorage.getItem('language') || 'es'
    const country = localStorage.getItem('country') || 'es'

    const { t } = this.props;
    return (
      <div>
      	<div className="terms-main">
        	<div className="terms-section">
        		<div className="container">
        			<div className="col-md-12">
                <h6 className="text-center">AVISO LEGAL</h6>
              </div>
              <div className="col-md-10 offset-md-1 legal-content">
              	<h5 className="text-center">NOTA: Introducir en la parte inferior izquierda del Home del Sitio Web</h5>
              	<p>En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio electrónico, le informamos que el titular del sitio Web www.agilar.es es:</p>
              	<div className="address-data">
	              	<p>Nombre.- Agilar Spain S.L.,</p>
	              	<p>CIF: B66564774</p>
	              	<p>Dirección: Avenida de Manoteras 32,28050 Madrid.</p>
	              	<p>Telf 917.526.017</p>
	              	<a href="# ">info@agilar.org</a>
              	</div>
              	<p>inscrita en el registro mercantil de Madrid, al tomo ___, libro __, folio __ Hoja______, Inscripción __</p>
              	<p>El acceso y navegación del usuario en el Sitio Web implica el conocimiento y la aceptación sin reservas de las Condiciones de Uso del sitio Web.</p>
              	<p>A efectos informativos, el presente sitio web se mantiene y contiene con los procedimientos estándar de seguridad generalmente aceptados con la finalidad de proteger la información contenida así como la que pueda ser proporcionada por los visitantes. El objetivo es el de evitar el uso inadecuado, pérdida o modificación de contenidos e información.</p>
              	<p>Le informamos que sólo el personal autorizado tiene acceso a la información personal y de identificación que un visitante pueda ofrecer a través del sitio.</p>
              	<p>Le recordamos que nuestro sitio puede contener enlaces a otros sitios que pueden no estar controlados por las mismas políticas de privacidad o bien que el propio proceso tecnológico, en su evolución, puede situar las informaciones que circulan por la red en una posición de debilidad en determinadas circunstancias.</p>
              	<p>El sitio web puede ofrecer determinada información sobre temas concretos a efectos explicativos, por lo tanto podría ser incompleta a los efectos que el visitante deseara tomar decisiones sobre sus negocios o decisiones personales. La información presentada no es, en ningún caso, un servicio de consultoría. Antes de tomar cualquier decisión consulte de forma personalizada con profesionales. Por lo tanto, no se acepta responsabilidad alguna por las decisiones que alguien pueda adoptar de la sola lectura de la información contenida en nuestra web. El titular de la web no es responsable de cualquier daño específico, indirecto o incidental derivado de la navegación por la red y el uso de nuestro sitio.</p>
              	<p className="pb-100">El visitante asume su propia responsabilidad derivado del empleo de éste sitio o de la información contenida.</p>
              </div>
        		</div>
        	</div>
        </div>
        <div className="footer-one fixed-bottom">
          <div className="container d-none">
            <div className="footer-content">
              <div className="row col-md-10 offset-md-1 footer-data pb-2">
                <div className="footer-item">
                  <h5>buenos aires</h5>
                  <p>Com. Clodomiro Urtubey 2327</p>
                  <p>1430 Buenos Aires (Argentina)</p>
                  <p>buenosaires@agilar.com </p>
                  <p>+54 11 4544 1912 </p>
                  <p>M-F from 8h to 17h</p>
                </div>
                <div className="footer-item gent-item">
                  <h5>gent</h5>
                  <p>Koning Albertlaan 198</p>
                  <p>9000 Gent (Bélgica)</p>
                  <p>gent@agilar.com</p>
                  <p>+32 9 335 4013</p>
                  <p>M-F from 9h to 17h</p>
                </div>
                <div className="footer-item">
                  <h5>madrid</h5>
                  <p>Avenida Manoteras, 32 </p>
                  <p>28050 Madrid (España)</p>
                  <p>madrid@agilar.com</p>
                  <p>+34 917 526 017</p>
                  <p>M-F from 9h to 17h</p>
                </div>
              </div>
              <div className="col-md-8 offset-md-2 social-box">
                <div className="col-md-12 social-content">
                  <div className="social-link">
                    <p>¡Síguenos en redes sociales para estar al tanto de nuestras novedades!</p>
                    <a href = "https://twitter.com/" target = "blank" >
                      <img src="/images/twitter.png" className="social-icons" alt="twitter"/>
                    </a>
                    <a href = "https://www.linkedin.com/" target = "blank" >
                      <img src="/images/linkdin.png" className="social-icons" alt="linkdin"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-main">
            <div className="row col-md-10 offset-md-1 footer-main-row">
              <ul className="list-inline">
                <li className="list-inline-item agilar-copyright">
                  &copy;{new Date().getFullYear()} - Agilar
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/terms`} >
                    {t('home.footer.conditions_of_use')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/legal-warning`} >
                    {t('home.footer.legal_notice')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/privacy-policy`} >
                    {t('home.footer.privacy_policy')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/cookies-policy`} >
                    {t('home.footer.cookies_policy')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('common')(Legal);
