import React from "react"

const ComponentSwitch = ({activeTab, children}) => {
    if (isNaN(activeTab))
        return null
    if (activeTab < 0)
        return null
    if (!children || activeTab >= children.length)
        return null
    return children[activeTab]
}

export default ComponentSwitch
