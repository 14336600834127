import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import './index.css'
import {translate} from 'react-i18next';
import CoursePresentation from "../courses/CoursePresentation";
import axios from "axios";
import {handleServerError} from "../../utils/request";

class CategoryInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            training: {},
        };
    }

    componentDidMount() {
        this.props.setActiveMenu('services')
        const {t} = this.props;
        document.title = "Agilar | " + t('home.title.training_detail');
        const {match} = this.props;
        const category = match.params.category;
        const country = localStorage.getItem('country')
        const language = localStorage.getItem('language')

        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/categories/${category}?language=${language}&country=${country}`)
            .then(response => {
                this.setState({training: response.data})
            })
            .catch(error => handleServerError(this.props.history, error, country, language))
    }


    render() {
        const {t} = this.props;
        const i8n_language = localStorage.getItem('language') || 'es'
        const country = localStorage.getItem('country') || 'es'

        return (
            <div className="training-description">
                <div className="container">
                    <div className="col-md-10 offset-md-1">
                        <Link to={`/${country}/${i8n_language}/trainings`}>
                            &#x3c;&nbsp;{t('services.courses_list.back_to_all')}
                        </Link>
                        <CoursePresentation training={this.state.training}/>
                    </div>
                </div>
            </div>
        )

    }
}

export default translate('common')(withRouter(CategoryInfo));
