import React, {useState} from "react"
import styles from "./Landing.module.css";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {COUNTRY_TO_LANGUAGE_MAP, PREFERRED_COUNTRY_KEY} from "../../utils/Constants";

const Landing = (props) => {
    const containerClass = clsx("container", styles.container)
    const mapContainerClass = clsx("d-none d-md-flex", styles.mapContainer)
    const [selectedCountry, setSelectedCountry] = useState("be");
    const imagePath = `/images/countries/map_${selectedCountry}.png`;
    const history = useHistory();

    const preferredCountry = localStorage.getItem(PREFERRED_COUNTRY_KEY);
    if (preferredCountry) {
        history.push(`/${preferredCountry}/${COUNTRY_TO_LANGUAGE_MAP[preferredCountry]}`)
    }

    const selectCountry = (country) => {
        localStorage.setItem(PREFERRED_COUNTRY_KEY, country);
        history.push(`/${country}/${COUNTRY_TO_LANGUAGE_MAP[country]}`)
    }

    return (
        <div className={containerClass}>
            <img className={styles.logo} src="/images/agilar-logo.png" alt="Agilar Logo"/>
            <div className={styles.content}>
                <h3 className={styles.title}>Welcome to Agilar</h3>
                <h4 className={styles.subTitle}>Please chose your closest country or language of preference</h4>
                <div className={styles.countriesContainer}>
                    <div className={styles.countriesList}>
                        <div className={styles.country}
                             onMouseOver={() => setSelectedCountry("be")}
                             onClick={() => selectCountry("be")}>
                            <img src="/images/countries/flags/be.png" alt="Belgium"/>
                            <p className={styles.name}>Belgium (english)</p>
                        </div>
                        <div className={styles.country}
                             onMouseOver={() => setSelectedCountry("es")}
                             onClick={() => selectCountry("es")}>
                            <img src="/images/countries/flags/es.png" alt="Spain"/>
                            <p className={styles.name}>Spain</p>
                        </div>
                        <div className={styles.country}
                             onMouseOver={() => setSelectedCountry("ar")}
                             onClick={() => selectCountry("ar")}>
                            <img src="/images/countries/flags/ar.png" alt="Argentina / Latin America"/>
                            <p className={styles.name}>Argentina / Latin America</p>
                        </div>
                    </div>
                    <div className={mapContainerClass}>
                        <img src={imagePath} alt="selected country"/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Landing;
