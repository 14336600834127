import React from "react"
import "./Progress.css"
import ProgressEntry from "./ProgressEntry";

const Progress = ({entries, activeEntry, handleEntryClick, className}) => {
    const totalEntries = entries.length - 1;
    return (
        <div className={`history-progress ${className}`}>
            {
                entries.map((entry, index) => {
                    return (
                        <ProgressEntry key={index}
                                       name={entry}
                                       active={index === activeEntry}
                                       last={index === totalEntries}
                                       handleClick={() => handleEntryClick(index)}/>
                    )
                })
            }
        </div>
    )
}

export default Progress;