import React from "react"
import classes from "./FloatingLabelInput.module.css"

const FloatingLabelInput = ({id, disabled, type, name, className, isRequired, required, onChange, label, value }) => {
    return (
        <>
            <input id={id}
                   type={type}
                   name={name}
                   className={`${classes.agilarInput} ${className} ${isRequired ? classes.isRequired : ''}`}
                   onChange={onChange}
                   placeholder="placeholder"
                   value={value}
                   disabled={disabled}
                   required={required}/>
            <label htmlFor={id} className={classes.agilarLabel}>{label}</label>
        </>

    )
}

export default FloatingLabelInput
