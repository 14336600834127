import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {translate} from 'react-i18next';
import './index.css'
import ContactGent from "./offices/ContactGent";
import ContactBuenosAires from "./offices/ContactBuenosAires";
import ContactMadrid from "./offices/ContactMadrid";


class Addresses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    getWorkingHours(start, end) {
        const lang = localStorage.getItem('language');
        return lang === "es" ?
            `L-V de ${start}h a ${end}h` :
            `M-F from ${start}h a ${end}h`;
    }

    getCountryContact = () => {
        const country = localStorage.getItem('country');
        if (country === 'be') {
            return (
                <>
                    <ContactGent getWorkingHours={this.getWorkingHours}/>
                    <ContactMadrid getWorkingHours={this.getWorkingHours}/>
                    <ContactBuenosAires getWorkingHours={this.getWorkingHours}/>
                </>
            )
        }
        if (country === 'es') {
            return (
                <>
                    <ContactMadrid getWorkingHours={this.getWorkingHours}/>
                    <ContactGent getWorkingHours={this.getWorkingHours}/>
                    <ContactBuenosAires getWorkingHours={this.getWorkingHours}/>
                </>
            )
        }
        if (country === 'ar') {
            return (
                <>
                    <ContactBuenosAires getWorkingHours={this.getWorkingHours}/>
                    <ContactGent getWorkingHours={this.getWorkingHours}/>
                    <ContactMadrid getWorkingHours={this.getWorkingHours}/>
                </>
            )
        }

    }

    render() {
        const i8n_language = localStorage.getItem('language') || 'es'
        const country = localStorage.getItem('country') || 'es'
        const {t} = this.props;

        const addresses = this.getCountryContact()
        const hasQR = country === 'ar';

        return (
            <div className="footer-one">
                <div className="container footer-content">
                    <div className="row footer-data pb-2 justify-content-between">

                        {addresses}

                        {country === "ar" &&
                        <div className="footer-item col-sm-6 col-md-4 col-xl-2 py-sm-2">
                            <div className="footer-item-content">
                                <a href="http://qr.afip.gob.ar/?qr=t26WGOsz0m-UV-I1fry5cQ,," target="_F960AFIPInfo">
                                    <img className="argentina-qr" src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                                         border="0"/>
                                </a>
                            </div>
                        </div>
                        }

                    </div>
                    <div className="col-md-8 offset-md-2 social-box">
                        <div className="col-md-12 social-content">
                            <div className="social-link justify-content-center">
                                <p>{t('home.footer.follow_us')}</p>

                                {country === "es" &&
                                <a href="https://twitter.com/AgilarSpain" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/twitter.png" className="social-icons" alt="twitter"/>
                                </a>
                                }

                                <a href="https://www.linkedin.com/company/agilar/" target="_blank"
                                   rel="noopener noreferrer">
                                    <img src="/images/linkdin.png" className="social-icons" alt="linkdin"/>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row col-md-10 offset-md-1 footer-main-row">
                        <ul className="list-inline">
                            <li className="list-inline-item agilar-copyright">
                                &copy;{new Date().getFullYear()} - Agilar
                            </li>
                            <li className="list-inline-item">
                                <Link to={`/${country}/${i8n_language}/terms`}>
                                    {t('home.footer.conditions_of_use')}
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to={`/${country}/${i8n_language}/legal-warning`}>
                                    {t('home.footer.legal_notice')}
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to={`/${country}/${i8n_language}/privacy-policy`}>
                                    {t('home.footer.privacy_policy')}
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to={`/${country}/${i8n_language}/cookies-policy`}>
                                    {t('home.footer.cookies_policy')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('common')(Addresses);
