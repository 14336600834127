import React from "react";
import {translate} from "react-i18next";

const Forbidden = ({t}) => {
  return (
    <div className="container error-page">
      <h2>{t('errors.forbidden')}</h2>
    </div>
  )
}

export default translate('common')(Forbidden);
