import React from 'react'
import {translate} from "react-i18next";
import {Link} from "react-router-dom";
import "./Services.css"

const Services = ({t, country, i8n_language, categories, get_class}) => {

  const getClass = (name) => {
    switch (name) {
      case "certified_scrum_master":
        return "services-csm";
      case "certified_scrum_product_owner":
        return "services-cspo";
      default:
        return ""
    }
  }

  const getCategoryTitle = (category) => {
    return category[`title_${i8n_language}`] ? category[`title_${i8n_language}`] : t(`services.trainings.${category.name}.title`)
  }

  return (
    <section className="section services" id="section1">
      <div className="container mt-4">
        <div className="content">
          <h4 className="header text-uppercase"><span
            className="border-head">{t('home.sliders.our_services').substring(0, 1)}</span>{t('home.sliders.our_services').substring(1, t('home.sliders.our_services').length)}
          </h4>
        </div>
        <div className="services-row">
          <div className="row">
            <div className="col-md-4 service_box_main">
              <div className="services-box">
                <Link to={`/${country}/${i8n_language}/trainings`} className="text-uppercase services-link">
                  <span
                    className="data-head">{t('home.nav.training').substring(0, 2)}</span>{t('home.nav.training').substring(2, t('home.nav.training').length)}
                </Link>
                <div className="services-data">

                  {categories.map(category =>
                    <Link to={`/${country}/${i8n_language}/courses?category=${category.name}`}
                          className="text-uppercase services-link" key={category.id}>
                      <h6 className={getClass(category.name)}>
                        {getCategoryTitle(category)}
                      </h6>
                    </Link>
                  )}

                </div>
              </div>
            </div>

            <div className="col-md-5 service_box_main">
              <div className="services-box">
                <a className="text-uppercase services-link" href="# ">
                  <span
                    className="data-head">{t('home.nav.consulting').substring(0, 2)}</span>{t('home.nav.consulting').substring(2, t('home.nav.consulting').length)}
                </a>
                <div className="services-data">
                  <p>
                    {t('home.sliders.consulting_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export default translate('common')(Services);

