import React from "react"
import {useFormikContext} from "formik";
import InputWrapper from "../../../../../componentLibrary/InputWrapper";
import {translate} from "react-i18next";

const CompanyContact = ({t}) => {
    const {values, setValues, isSubmitting} = useFormikContext()
    const {students} = values

    const copyFromParticipant = () => {
        const participant = students[0]
        const contactPerson = participant ? `${participant.firstName} ${participant.lastName}`.trim() : ""
        const contactEmail  = participant ? participant.email.trim() : ""

        setValues({
            ...values,
            contactPerson,
            contactEmail
        }, false)
    }

    return (
        <>
            <div className="col-md-12 more-head text-right">
                <button type="button"
                        onClick={copyFromParticipant}
                        disabled={isSubmitting}
                        className="btn yellow-btn more-btn">
                    {t('services.course_registration.copy_contact_information')}
                </button>
            </div>
            <div className="form-row">
                <InputWrapper id="contactPerson "
                              name="contactPerson"
                              label={t('services.course_registration.contact_person')}
                              containerClass="col-md-4 px-sm-4 mb-5"
                              isRequired/>
                <InputWrapper id="contactEmail "
                              name="contactEmail"
                              label={t('services.course_registration.contact_email')}
                              containerClass="col-md-4 px-sm-4 mb-5"
                              isRequired/>
                <InputWrapper id="contactPhone "
                              name="contactPhone"
                              label={t('services.course_registration.contact_phone')}
                              containerClass="col-md-4 px-sm-4 mb-5"
                              isRequired/>
            </div>
        </>
    )
}

export default translate('common')(CompanyContact)
