import React from "react"
import {useHistory} from "react-router-dom";
import styles from "./PastCourse.module.css"
import classes from "../../trainings/NoTrainings/NoTrainings.module.css";
import {translate} from "react-i18next";
import {RoutingConstants} from "../../../utils/routingConstants";


const PastCourse = ({ t }) => {
    const history = useHistory()

    const message1 = t("pastTraining.line1");
    const upcomingCta = t("pastTraining.upcomingCta");
    const message2 = t("pastTraining.line2");

    return (
        <div className="training-description">
            <div className="container">
        <div className={styles.pastCourse}>
            <h4>
                <span className={classes.borderContent}>{message1.substring(0, 3)}</span>
                {message1.substring(3, message1.length)}&nbsp;
                <span className="call-to-action"
                      onClick={() => history.push(RoutingConstants.UPCOMING_TRAININGS())}>{upcomingCta}</span>.
            </h4>
        </div>
            </div>
        </div>
    )
}

export default translate('common')(PastCourse);
