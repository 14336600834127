import React from 'react'
import {translate} from "react-i18next";
import "./ManagingPartner.css"

const ManagingPartner = ({t, name, linkedin}) => {
    const imgSrc = `/images/partner-${name}.png`;
    return (
        <div className="managing-partner">
            <img src={imgSrc} alt={name}/>
            <div className="partner-content d-flex flex-column px-3 pb-3 pt-0">
                <h4 className="name align-self-center mb-2">{t(`about.partners.${name}.fullName`)}</h4>
                <p>{t(`about.partners.${name}.description`)}</p>
                <p className="mb-0">{t(`about.partners.${name}.title`)}</p>
                <span className="flex-grow-1"/>
                <a href={linkedin} rel="noreferrer noopener" target="_blank">{t(`about.partners.${name}.linkText`)}</a>
            </div>
        </div>

    )
}

export default translate('common')(ManagingPartner)