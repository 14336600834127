import React from "react"
import {useFormikContext} from "formik";
import InputWrapper from "../../../../../componentLibrary/InputWrapper";
import {translate} from "react-i18next";
import {EMPTY_STUDENT} from "../index";

const CompanyStudents = ({t}) => {
    const {values, setValues, isSubmitting} = useFormikContext()
    const { students }= values

    const addStudent = () => {
        setValues({
            ...values,
            students: [...students, EMPTY_STUDENT()]
        }, false)
    }

    const removeStudent = (index) => {
        const newStudents = [...students]
        newStudents.splice(index, 1)
        setValues({
            ...values,
            students: newStudents
        })
    }

    return (
        <>
            {
                values.students.map((student, index) => (
                    <div className="form-row my-3" key={student.tempId}>
                        <div className="trainee-head col-md-12 px-sm-4 mb-3">
                            <h6>{t('services.course_registration.trainee')} {index + 1}</h6>
                            <img src="/images/cancel.png"
                                className="clickable"
                                 alt="cancel"
                                 onClick={() => removeStudent(index)}/>
                        </div>
                        <InputWrapper id={`student${index}FirstName`}
                                      name={`students.${index}.firstName`}
                                      label={t('services.registration_contact_us.first_name')}
                                      containerClass="col-md-6 px-sm-4"
                                      isRequired/>
                        <InputWrapper id={`student${index}LastName`}
                                      name={`students.${index}.lastName`}
                                      label={t('services.registration_contact_us.last_name')}
                                      containerClass="col-md-6 px-sm-4"
                                      isRequired/>
                        <InputWrapper id={`student${index}`}
                                      name={`students.${index}.email`}
                                      label={t('services.registration_contact_us.email_address')}
                                      containerClass="col-md-6 px-sm-4"
                                      isRequired/>
                    </div>)
                )
            }
            <div className="col-md-12 more-head text-right">
                <button type="button" onClick={addStudent}
                        disabled={isSubmitting}
                        className="btn yellow-btn more-btn">
                    {t('services.course_registration.add_more_trainees')}
                </button>
            </div>
        </>
    )
}

export default translate('common')(CompanyStudents)
