import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {translate} from 'react-i18next';
import './index.css';
import axios from "axios";
import queryString from "query-string";

class CourseRegistrationSuccess extends React.Component {

    state = {
        training: {},
        loading: true
    }

    componentDidMount() {
        const {match} = this.props;
        var training_id = match.params.id;
        const {t} = this.props;
        const lang = localStorage.getItem('language')
        const country = localStorage.getItem('country')
        const qs = queryString.parse(this.props.location.search);
        document.title = "Agilar | " + t('home.title.course_registration');
        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/courses/${training_id}`)
            .then(({data}) => this.setState({
                training: data,
                loading: false,
                type: qs.type || "private",
                waiting: qs.waiting,
                email: qs.email}))
            .catch(error => {
                switch (error.response.status) {
                    case 404:
                        this.props.history.push(`/${country}/${lang}/not-found`);
                        return;
                    case 403:
                        this.props.history.push(`/${country}/${lang}/forbidden`);
                        return;
                    default:
                        this.props.history.push(`/${country}/${lang}/server-error`);
                        return;
                }
            })
    }

    render() {
        const {t} = this.props
        const {training, loading, type, waiting, email} = this.state

        const language = localStorage.getItem('language')
        const country = localStorage.getItem('country')

        let msg = t(`services.course_registration.success.${waiting === 'true' ? 'waiting.' : ''}${type}`, {name: training.name})
        if (type === 'business' && email === 'true')
            msg = msg.concat(t('services.course_registration.success.confirmation'));

        return (
            <div className="training-description">
                <div className="container">
                    <div className="col-md-12 reg-form">
                        <Link to={`/${country}/${language}/trainings`} className="back_to_all">
                            &#x3c;&nbsp;{t('services.courses_list.back_to_all')}
                        </Link>
                        {!loading &&
                            <h4 className="registre-head registration-success mt-4 pt-3">
                                <span className="border-content">{msg.substr(0, 3)}</span>
                                {msg.substr(3, msg.length)}
                            </h4>
                        }
                    </div>
                </div>
            </div>
        )

    }
}

export default translate('common')(withRouter(CourseRegistrationSuccess));
