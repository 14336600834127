import React, {Component, useEffect} from "react"

const CourseDescription = ({description}) => {
    const descRef = React.createRef();

    const cspoImgSrc = "ckeditor_assets/pictures/171/content_scr20146-seals-final-cspo.png";
    const csmImgSrc = "ckeditor_assets/pictures/172/content_scr20146-seals-final-csm.png";

    useEffect(() => {
        descRef.current.querySelectorAll('img').forEach(el => {
            const imgSrc = el.getAttribute('src')
            el.setAttribute('src', process.env.REACT_APP_API_URL + imgSrc);
            if (imgSrc.indexOf(csmImgSrc) > -1) {
                el.classList.add('d-none')
            }
            if (imgSrc.indexOf(cspoImgSrc) > -1) {
                el.classList.add('d-none')
            }
        })
    }, [description])


    return <div ref={descRef} dangerouslySetInnerHTML={{__html: description}}/>
}

export default CourseDescription;