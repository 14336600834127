import React from 'react'
import {translate} from "react-i18next";
import "./About.css";
import {Link} from "react-router-dom";

const About = (props) => {
  const {t, language, country} = props;

  return (
    <section className="section about">
      <div className="container">
        <div className="col content">
          <h4 className="header text-uppercase">
              <span
                className="border-head">{t('home.who_we_are.who_we_are').substring(0, 1)}</span>{t('home.who_we_are.who_we_are').substring(1, t('home.who_we_are.who_we_are').length)}
          </h4>
        </div>
        <div className="col text-justify text-row">
          <p className="mt-2">{t(`home.who_we_are.copy.${country}.line1`)}</p>
          <p className="mt-2">{t(`home.who_we_are.copy.${country}.line2`)}</p>
          <p className="mt-2">{t(`home.who_we_are.copy.${country}.line3`)}</p>

          <Link to={`/${country}/${language}/who-we-are`} className="btn yellow-btn about-btn text-uppercase">
            { t('home.who_we_are.link') }
          </Link>
        </div>
      </div>
    </section>
  )
}

export default translate('common')(About);
