import React from "react"
import {useField, useFormikContext} from "formik";
import classes from "./InputWrapper.module.css"
import clsx from "clsx";
import FloatingLabelTextArea from "../FloatingLabelInput/FloatingLabelTextArea";

const TextAreaWrapper = (props) => {
    const [field, meta] = useField(props)
    const { isSubmitting } = useFormikContext()

    const containerClass = clsx(props.containerClass, classes.inputContainer)

    return (
        <div className={containerClass} style={{height: 'auto'}}>
            <FloatingLabelTextArea {...field} {...props} disabled={isSubmitting}/>
            {meta.touched && meta.error ? (
                <div className={classes.validationError}>{meta.error}</div>
            ) : null}
        </div>
    )
}

export default TextAreaWrapper
