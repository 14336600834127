import React, {useState, useEffect} from 'react';
import {translate} from 'react-i18next';
import useLocale from "../../../componentLibrary/hooks/useLocale";
import CourseList from "../CourseList";
import NoTrainings from "../../trainings/NoTrainings";
import LanguageDropdown from "../../LanguageDropdown";

const UpcomingCourses = ({trainings, loading, t}) => {
    const [country, language] = useLocale()
    const [languages, setLanguages] = useState([])
    const [filteredTrainings, setFilteredTrainings] = useState(trainings)
    const [selectedLanguage, setSelectedLanguage] = useState(null)

    useEffect(() => {
        setLanguages(trainings.filter(t => t).map(t => t.language).filter((value, index, self) => self.indexOf(value) === index))
        setSelectedLanguage(null)
        setFilteredTrainings(trainings)
    }, [trainings])

    useEffect(() => {
        const lang = selectedLanguage ? selectedLanguage.value : null;
        setFilteredTrainings(lang ? trainings.filter(t => t && t.language === lang) : trainings)
    }, [selectedLanguage])

    const coursesExist = loading || (!!filteredTrainings && filteredTrainings.length > 0)

    return (
        <div className="courses-main">
            <div className="courses-section">
                <div className="container courses-container">
                    <div className="col-md-12 courses-row">
                        {coursesExist ?
                            <>
                                <LanguageDropdown languages={languages}
                                                  selectedLanguage={selectedLanguage}
                                                  setSelectedLanguage={setSelectedLanguage}/>
                                <CourseList courses={filteredTrainings}
                                            language={language}
                                            country={country}/>
                            </> :
                            <NoTrainings/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default translate('common')(UpcomingCourses);
