import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {translate} from 'react-i18next';
import './index.css';
import axios from "axios";
import CoursePrice from "./CoursePrice";
import PrivateForm from "./CourseRegistration/PrivateForm";
import FreelancerForm from "./CourseRegistration/FreelancerForm";
import CompanyForm from "./CourseRegistration/CompanyForm";
import ComponentSwitch from "../../componentLibrary/ComponentSwitch";
import useLocale from "../../componentLibrary/hooks/useLocale";
import {handleServerError} from "../../utils/request";
import PastCourse from "./CourseDetail/PastCourse";

const CourseRegistration = ({setActiveMenu, t}) => {
    const [country, language] = useLocale()
    const [training, setTraining] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const history = useHistory()
    const {id} = useParams()


    const onSuccess = (registration) => {
        let url = `/${country}/${language}/courses/${training.id}/success?type=${registration.payer_type}`
        if (training.full)
            url = url.concat('&waiting=true')
        if (registration.payer_type === 'business' && registration.send_email_to_all_registrants)
            url = url.concat('&email=true')
        history.push(url)
    }

    useEffect(() => {
        document.title = "Agilar | " + t('home.title.course_registration');
        setActiveMenu('services')
        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/courses/${id}`)
            .then(response => setTraining(response.data))
            .catch(error => handleServerError(history, error, country, language))
    }, [])

    if (training && training.start_date) {
        const startDate = new Date(training.start_date)
        const today = new Date()
        if (today >= startDate)
            return <PastCourse/>
    }

    if (!training)
        return null

    return (
        <div className="training-description">
            <div className="container">
                <div className="col-md-12 reg-form">
                    <Link to={`/${country}/${language}/courses?category=${training.category}`}>
                        &#x3c;&nbsp;{t('services.courses_list.back_to_all_courses')}
                    </Link>
                    <h4 className="registre-head pl-4 pt-3">{training.full ? t('services.course_registration.waiting_list') :
                        t('services.course_registration.register_now_to')} {training.name}, {training.training_date}</h4>

                    <div className="row mx-0 pt-4 pb-5">
                        <div className="col-md-9">
                            <div className="description-box">
                                <div className="registration-content">
                                    <ul className="list-inline pl-4">
                                        <li className="list-inline-item pl-3">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   id="private-radio"
                                                   readOnly
                                                   checked={activeTab === 0}
                                                   onClick={() => setActiveTab(0)}/>
                                            <label className="form-check-label" htmlFor="private-radio">
                                                {t('services.course_registration.private')}
                                            </label>
                                        </li>
                                        <li className="list-inline-item pl-5">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="payer_type"
                                                   id="freelancer-radio"
                                                   readOnly
                                                   checked={activeTab === 1}
                                                   onClick={() => setActiveTab(1)}/>
                                            <label className="form-check-label" htmlFor="freelancer-radio">
                                                {t('services.course_registration.freelancer')}
                                            </label>
                                        </li>
                                        <li className="list-inline-item pl-5">
                                            <input className="form-check-input"
                                                   type="radio"
                                                   name="payer_type"
                                                   id="company-radio"
                                                   readOnly
                                                   checked={activeTab === 2}
                                                   onClick={() => setActiveTab(2)}/>
                                            <label className="form-check-label" htmlFor="company-radio">
                                                {t('services.course_registration.company')}
                                            </label>
                                        </li>
                                    </ul>
                                    <ComponentSwitch activeTab={activeTab}>
                                        <PrivateForm training={training} onSuccess={onSuccess}/>
                                        <FreelancerForm training={training} onSuccess={onSuccess}/>
                                        <CompanyForm training={training} onSuccess={onSuccess}/>
                                    </ComponentSwitch>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="description-right">
                                <h5>{t('services.courses_list.schedule')}</h5>
                                <p className="mb-0">{training.training_schedule_date}</p>
                                <p>{training.schedule}</p>
                                <h5>{t('services.courses_list.location')}</h5>
                                <p>{training.city}</p>
                                <h5>{t('services.courses_list.language')}</h5>
                                <p>{t(`services.course_registration.language.${training.language}`)}</p>
                                <h5>{t('services.courses_list.trainer')}</h5>
                                <div className="trainee-profile">
                                    <img src={training.trainer_image} alt="tr"/>
                                    <p>{training.trainer}</p>
                                </div>

                                {!!training.co_trainer &&
                                <div>
                                    <h5>{t('services.courses_list.co_trainer')}</h5>
                                    <div className="trainee-profile">
                                        <img src={training.co_trainer_image} alt="tr"/>
                                        <p>{training.co_trainer}</p>
                                    </div>
                                </div>
                                }

                                {!!training.partner &&
                                <div>
                                    <h5>{t('services.courses_list.partner')}</h5>
                                    <div className="trainee-profile">
                                        <a href={training.partner} target="_blank"
                                           rel="noopener noreferrer">
                                            <img className="partner-image"
                                                 src={training.partner_logo_url} alt="partner"/>
                                        </a>
                                    </div>
                                </div>
                                }

                                <h5>{t('services.courses_list.price')}</h5>
                                <CoursePrice course={training}/>
                                <div className="trainee-btn">
                                    <Link
                                        to={`/${country}/${language}/courses/${training.id}/contact`}
                                        className="btn yellow-btn btn-training mt-4">
                                        {t('services.courses_list.contact_us')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default translate('common')(CourseRegistration);
