import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'

import { translate } from 'react-i18next';

class Privacy extends Component {

  componentDidMount(){
    const { t } = this.props;
    document.title = "Agilar | "+ t('home.footer.privacy_policy');
  }

  render () {
    const i8n_language = localStorage.getItem('language') || 'es'
    const country = localStorage.getItem('country') || 'es'

    const { t } = this.props;
    return (
      <div>
      	<div className="terms-main">
        	<div className="terms-section">
        		<div className="container">
        			<div className="col-md-12">
                <h6 className="text-center">Política de Privacidad</h6>
              </div>
              <div className="col-md-10 offset-md-1">
                <p>La Política de Privacidad de <b className="highlighted-text">AGILAR SPAIN SL.,</b> describe las prácticas de la compañía en relación con el cumplimiento del Reglamento 2016/679 del parlamento europeo y del consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (En adelante, RGPD).</p>
                <Table className="table-privacy">
                  <tbody>
                    <tr>
                      <td>Responsable del Tratamiento</td>
                      <td>Agilar Spain S.L.,<br/>B66564774<br/>Avenida de Manoteras 32, 28050 Madrid.<br/>917.526.017<br/><a href="# ">info@agilar.org</a></td>
                    </tr>
                    <tr>
                      <td>Base Jurídica</td>
                      <td>
                        <ul className="privacy-list">
                          <li>Consentimiento   como   consecuencia   delenvío de una dirección de correo electrónico (Art 6.1.a RGPD).</li>
                          <li>Tratamiento necesario para la ejecución deun contrato en el que el interesado es parte,(Art 6.1.b RGPD).</li>
                          <li>Tratamiento necesario para la satisfacciónde intereses legítimos de responsable (Art6.1.f RGPD).</li>
                        </ul>
                        <p>Categorías de datos: nombre, apellidos, dirección de correo electrónico requeridos, y opcionales teléfono, empresa y datos de facturación</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Finalidad del Tratamiento</td>
                      <td>
                        <p className="mb-0">Registrar el nombre y correo electrónico a efectos de envío de información, mantener comunicaciones y atender solicitudes de contacto.</p>
                        <p className="mb-0">Registrar una solicitud de inscripción o reserva a cursos de formación.</p>
                        <p className="mb-0">Participar en programas de formación y prestar los servicios contratados.</p></td>
                    </tr>
                    <tr>
                      <td>Conservación de los datos</td>
                      <td>
                        <p className="mb-0">Se conservarán hasta la finalización de la relación contractual y durante el plazo legal para cubrir responsabilidades.</p>
                        <p className="mb-0">Se conservarán de forma indefinida salvo el</p>
                        <p className="mb-0">interesado solicite la cancelación o supresión.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Destinatarios</td>
                      <td>
                        <p className="mb-0">Destinatarios de cesiones de datos: empresas del grupo:_____________</p> <p className="mb-0">Transferencias internacionales de datos: no están previstas.</p>
                      </td>
                    </tr>
                    <tr>
                      <td>Derechos de las personas interesadas</td>
                      <td>Pueden ejercer el derecho de acceso, rectificación, de supresión, de portabilidad, de limitación y de oposición al tratamiento de sus datos. Enviando una solicitud escrita y fundamentada al domicilio social o a <a href="# ">info@agilar.org</a>  Asimismo, pueden presentar reclamaciones que consideren ante la <a href="# ">www.agpd.es</a>.</td>
                    </tr>
                  </tbody>
                </Table>
                <p>Agilar Spain S.L., reconoce que la privacidad es importante para usted. Queremos poner a su disposición la manera en la que recopilamos, usamos y divulgamos la información, es por ello que esta Política de privacidad describe las prácticas de privacidad referentes a los datos que recopilamos a través de:</p>
                <ul className="privacy-list">
                  <li>Los sitios web que operamos y desde los que accede a esta Política de Privacidad, y otros sitios web, blogs propiedad de Agilar Spain S.L., y de las empresas del grupo.</li>
                  <li>Las redes sociales en que participamos desde las cuales accede a esta Política de Privacidad.</li>
                  <li>Mensajes de correo electrónico con formato HTML que le enviamos con el enlace a esta Política de Privacidad.</li>
                  <li>Sus comunicaciones con nosotros cuando nos solicita un servicio o se pone en contacto con nosotros.</li>
                </ul>
                <p>Nos referimos a nuestros Sitios web, Páginas en redes sociales y otras en conjunto como los "Servicios”. Al utilizar los Servicios, usted acepta los términos y condiciones de esta Política de Privacidad.</p>
                <h5>Responsable del Tratamiento de sus datos personales.</h5>
                <p>El Responsable del tratamiento de datos de carácter personal es Agilar Spain S.L., en adelante AGILAR con CIF núm. B66564774 y domicilio en Avenida de Manoteras 32, 28050 Madrid, inscrita en el registro mercantil de Madrid, al tomo …, libro …, folio …., Hoja……., Inscripción ….</p>
                <p>AGILAR, como responsable del tratamiento de datos personales, garantiza un nivel adecuado y coherente de protección de las personas físicas en lo que respecta a sus datos personales, objeto de tratamiento, de acuerdo con el RGPD y solo trata datos personales lealmente, con plena responsabilidad, y de acuerdo con las bases jurídicas que permiten la licitud del tratamiento, según el artículo 6 del RGPD.</p>
                <p>Le informamos que AGILAR mantiene relación con usuarios mediante blogs de titularidad de la compañía y desde los cuales puede acceder a información y suscribirse a newsletters.</p>
                <p>Con el propósito de dar cumplimiento al Reglamento general de protección de datos, AGILAR aplica el principio de transparencia en el tratamiento de datos personales, facilitando a las personas interesadas una información concisa, fácilmente accesible, completa y con un lenguaje fácil de entender, y ofreciendo una dirección de correo electrónico para el ejercicio de los derechos de acceso, de rectificación y supresión, derecho a la limitación del tratamiento, de portabilidad de los datos y de oposición, en el que serán atendidos de modo rápido y eficaz info@agilar.org</p>
                <h5>Recopilación de Información personal</h5>
                <p>La <b className="highlighted-text">“Información personal”</b> son los datos que lo identifican individualmente o se relacionan con una persona identificable, nuestra entidad puede recopilar distinta Información personal de acuerdo con la Ley, como, nombre, apellidos, dirección de correo electrónico, teléfono, empresa y datos de facturación y de pago.</p>
                <p>Si nos proporciona cualquier Información personal de otras personas en relación con los Servicios usted declara tener facultades para hacerlo y para permitirnos usar dicha información conforme a esta Declaración de privacidad.</p>
                <h5>Cómo recopilamos la Información personal</h5>
                <p>Recopilamos la Información personal de varias maneras:</p>
                <ul className="privacy-list">
                  <li>Solicitud de servicios.</li>
                  <li>También recopilamos Información personal cuando asiste a actividades o a los eventos promocionales que organizamos o en los que participamos, o cuando proporciona su Información personal para participar de un evento o formación.</li>
                  <li>Recopilamos Información personal cuando hace una solicitud por teléfono o se comunica con nosotros por correo electrónico. Estas comunicaciones pueden registrarse con fines de control de calidad y capacitación.</li>
                  <li>Recopilamos Información personal de profesionales independientes que tienen relación con AGILAR.</li>
                </ul>
                <p>Recopilamos otros datos que son datos que generalmente no revelan su identidad específica y no se relacionan directamente con una persona y ellos incluyen:</p>
                <ul className="privacy-list">
                  <li>Información personal de bases de datos públicas, <b className="highlighted-text">a través de su navegador o dispositivo.</b>  Recopilamos cierta información por medio de su navegador o su dispositivo automáticamente, como su dirección MAC (control de acceso al medio), tipo de computadora (Windows o Macintosh), resolución de pantalla, nombre y versión del sistema operativo, fabricante y modelo del dispositivo, idioma, tipo y versión del navegador de Internet y el nombre y versión de los Servicios en línea (p. ej., las Aplicaciones) que utilice. Empleamos esta información para asegurarnos de que los Servicios en línea funcionen de manera adecuada.</li>
                  <li>Datos de <b className="highlighted-text">uso de la Aplicación.</b> Recopilamos cierta información cuando usted descarga y usa una Aplicación, como información del uso de la Aplicación, la fecha y hora en la que la Aplicación accede a nuestros servidores y qué información y archivos se han descargado a la Aplicación en base a su número de dispositivo.</li>
                  <li><p>Información recopilada a través de <b className="highlighted-text">Cookies.</b>  Recopilamos ciertos datos de las cookies, que son datos almacenados directamente en el ordenador o dispositivo móvil que esté utilizando. Las cookies nos permiten recopilar información, como el tipo de navegador, el tiempo que pasa en los Servicios en línea, páginas visitadas, URL de referencia, preferencias de idioma y otros datos de tráfico agregados. Utilizamos la información con fines de seguridad, para facilitar la navegación, mostrar la información de manera más efectiva, recopilar información estadística, personalizar su experiencia mientras utiliza los Servicios en línea y reconocer su computadora para asistirle en el uso de los Servicios en línea. También recopilamos información estadística sobre el uso de los Servicios en línea para mejorar continuamente su diseño y funcionalidad, entender cómo se utilizan y ayudarnos a resolver problemas. Puede consultar nuestra <b className="highlighted-text">Política de cookies</b></p>
                    <p>Las cookies también nos permiten seleccionar cuáles de nuestros avisos publicitarios y ofertas podrían gustarle y mostrárselos cuando utilice los Servicios en línea o enviarle correos electrónicos de comercialización. También utilizamos cookies y otras tecnologías para rastrear respuestas a avisos publicitarios en línea y correos electrónicos de comercialización.</p>
                    <p>Si no desea que recopilemos datos con cookies, puede revisar la información sobre cómo controlar las cookies en <a href="# ">http://www.allaboutcookies.org/manage-cookes/index.htm.</a></p>
                    <p>Puede elegir aceptar las cookies cambiando las opciones de su navegador o administrando sus preferencias de rastreo haciendo clic en “Preferencias de seguimiento” en la parte inferior de nuestra página de inicio. Sin embargo, si no desea aceptar las cookies, es posible que tenga inconvenientes al utilizar los Servicios en línea. Por ejemplo, es posible que no podamos reconocer su computadora, y tendrá que iniciar sesión cada vez que nos visite. Tampoco recibirá nuestros anuncios y ofertas que son relevantes para sus intereses y necesidades. En este momento, no respondemos a las señales de "No rastrear" de su navegador.</p>
                  </li>
                  <li>Etiquetas de pixel y otras tecnologías similares. Recopilamos datos utilizando etiquetas de pixel (también conocidas como balizas web y GIF transparentes) en relación con algunos Servicios en línea para, entre otros, rastrear las acciones de los usuarios de los Servicios en línea (incluso destinatarios de correos electrónicos), medir el éxito de nuestras campañas publicitarias y compilar estadísticas sobre el uso de los Servicios en línea.</li>
                  <li>Análisis. Recopilamos datos a través de Google Analytics y Adobe Analytics, que utilizan cookies y otras tecnologías para recopilar y analizar datos sobre el uso de los Servicios. Estos servicios recopilan datos sobre el uso de otros sitios web, aplicaciones y recursos en línea. Puede obtener más información acerca de las prácticas de Google en <a href = "# ">www.google.com/policies/privacy/‌partners/</a> y puede desactivarlas al descargar el complemento de exclusión de Google Analytics de su navegador en <a href = "# ">https://tools.google.com/dlpage/gaoptout</a> . Puede obtener más información sobre Adobe y desactivar las actividades de análisis en <a href = "# ">http://www.adobe.com/privacy/opt-out.html</a>.</li>
                  <li>Su dirección IP. Recopilamos su dirección IP, que es un número que su Proveedor de Servicios de Internet (ISP) le asigna automáticamente a su computadora. Cada dirección de IP queda identificada y registrada automáticamente en nuestros registros de servidor cuando un usuario accede a los Servicios en línea, junto con la hora de la visita y las páginas visitadas. Utilizamos las direcciones IP para calcular los niveles de uso, diagnosticar problemas del servidor y administrar los Servicios en línea. También podemos deducir su ubicación aproximada conociendo su dirección IP.</li>
                  <li>Datos agregados. Podemos agregar los datos que recopilamos y estos datos agregados no lo identificarán personalmente a usted ni a ningún otro usuario.</li>
                </ul>
                <h5>Finalidades</h5>
                <p>Utilizamos la Información personal para proporcionarle información sobre nuestros servicios y nuevas ofertas como se detalla a continuación:</p>
                <ul className="privacy-list">
                  <li>Para prestar servicios de consultoría y formación, así como gestionar las relaciones comerciales con los clientes, poder realizar reservas y enviar confirmaciones o mensajes.</li>
                  <li>Envío de newsletters.</li>
                  <li>Envío de comunicaciones comerciales.</li>
                  <li>Personalizar su solicitud de acuerdo con sus preferencias personales.</li>
                  <li>Comunicarle ofertas adaptadas a sus preferencias personales.</li>
                  <li>El cumplimiento de las obligaciones legales que resulten de aplicación al Responsable.</li>
                </ul>
                <p>Utilizaremos la Información personal para manejar nuestra relación comercial con usted, porque tenemos un interés legítimo, además utilizaremos la Información personal para comunicarnos con usted con su consentimiento. En algunos casos, le solicitaremos que nos proporcione Información personal u otros datos directamente. Si no nos proporciona los datos que solicitamos (*) quizá no podamos proporcionar la información o servicios solicitados.</p>
                <h5>Destinatarios</h5>
                <p>Tus datos personales no serán cedidos a ningún tercero que no pertenezca al Grupo AGILAR. A estos efectos le informamos que forma parte del Grupo las siguientes compañías________________________________________-</p>
                <p>En determinadas circunstancias, tus datos también podrán ser cedidos a organismos y autoridades públicas (administrativas o judiciales) en aquellos casos en los que una norma legal así lo establezca.</p>
                <h5>Entidades fuera del Responsable</h5>
                <p>Esta Declaración de privacidad no aborda la privacidad, los datos y las prácticas de entidades titulares de páginas web o blogs fuera del grupo AGILAR y no somos responsables de ellos; la inclusión de un enlace en la página web no implica que nosotros o nuestros afiliados avalemos el sitio o servicio vinculado, salvo la declaración de privacidad de dicho enlace lo señale expresamente. No tenemos control sobre la recopilación de su Información personal por terceros ni somos responsables del uso y divulgación que de ella hagan.</p>
                <p>Tampoco somos responsables de la recopilación, uso, divulgación, políticas y prácticas de seguridad de otras organizaciones, como Facebook, Apple, Google, twiter, youtube, instagram, Microsoft, RIM o cualquier otro desarrollador o proveedor de aplicaciones, plataformas de redes sociales, sistemas operativos, incluyendo cualquier Información personal que usted divulgue a otras organizaciones a </p>
                <h5>Anunciantes Externos</h5>
                <p>Podemos utilizar compañías de publicidad o comercializadoras externas para publicar anuncios o vender productos. Para hacerlo, estas compañías podrían colocar o reconocer una cookie única en su navegador (incluyendo el uso de etiquetas de pixel).</p>
                <h5>Seguridad</h5>
                <p>En nuestra empresa, tratamos de utilizar las medidas organizativas, técnicas y administrativas razonables para proteger la Información personal. Lamentablemente, no se puede garantizar que un sistema de almacenamiento o transmisión de datos sea 100% seguro. Si sospecha de que su interacción con nosotros ya no es segura (p. ej., si cree que la seguridad de su cuenta está en peligro), notifíquenos inmediatamente utilizando la información que encontrará más abajo.</p>
                <h5>Alternativas de acceso y conservación</h5>
                <p>Usted tiene alternativas en cuanto a cómo usamos sus datos, por eso le damos la información necesaria para que tome las decisiones que considere más adecuadas.</p>
                <p>Si ya no desea recibir correos electrónicos de información o comercialización, puede elegir darse de baja o siga las instrucciones contenidas en cualquiera de los correos electrónicos que le enviamos.</p>
                <p>Trataremos de cumplir con su solicitud lo más pronto posible. Tenga en cuenta que si decide no recibir más correos de comercialización nuestros, podemos seguir enviándole mensajes administrativos importantes que usted no puede bloquear.</p>
                <h5>Cómo puede acceder a su Información personal, cambiarla o suprimirla:</h5>
                <p>Si desea revisar, corregir, actualizar, suprimir, restringir o eliminar la Información personal que nos haya dado previamente, o si desea recibir una copia electrónica de su Información personal para transmitirla a otra compañía (si la ley protege su derecho a la portabilidad de datos), puede contactarnos en info@agilar.com  o a nuestro domicilio social Avenida de Manoteras 32, 28050 Madrid.</p>
                <p>En su solicitud, por favor indique claramente cuál Información personal desea cambiar, si desea suprimir su Información personal de nuestra base de datos o las limitaciones que desee establecer con respecto a nuestro uso de su Información personal. Para su protección, solo podemos implementar las solicitudes con respecto a la Información personal asociada a la dirección de correo electrónico utilizada para enviar su solicitud, y es posible que tengamos que verificar su identidad antes de implementar los cambios. Trataremos de cumplir con su solicitud tan pronto como sea razonablemente posible.</p>
                <p>Tenga en cuenta que quizás debamos retener cierta información con fines de registro o para completar alguna transacción que haya iniciado antes de solicitar el cambio o eliminación También puede haber información residual que permanecerá en nuestras bases de datos y otros registros que no será eliminada.</p>
                <p>En el supuesto de que no obtenga una respuesta satisfactoria y desee formular una reclamación u obtener mayor información al respecto de cualquiera de estos derechos, puede acudirse a la Agencia Española de Protección de Datos (www.agpd.es).</p>
                <h5>Conservación</h5>
                <p>Conservaremos su Información personal por el tiempo necesario para cumplir con los fines establecidos en esta Política de privacidad, es decir mientras se mantenga la relación de prestación de servicios, de comunicaciones o solicitud realizada y no se solicite la supresión.</p>
                <p>También se conservarán los datos, si existe una obligación legal.</p>
                <h5>Datos sensibles</h5>
                <p>A menos que se lo solicitemos específicamente, le pedimos que no nos envíe y que no comparta con nosotros ninguna Información o Dato sensible (p. ej., número de seguro social, número de identificación oficial, información relacionada con su origen étnico o racial, opiniones políticas, religión y otras creencias, salud, características biométricas o genéticas, antecedentes penales, membresías en sindicatos, procesos penales o administrativos, ni sentencias).</p>
                <h5>Transferencia a otros países</h5>
                <p>De conformidad con lo establecido en el artículo 13 del RGPD, te informamos que o se prevé realizar transferencias a un tercer país u organización internacional</p>
                <h5>Actualizaciones a esta Declaración de privacidad</h5>
                <p className="pb-100">La leyenda “Última actualización” en la parte superior de esta página indica cuándo fue la última vez que se modificó esta Política de privacidad. Cualquier cambio entrará en vigencia cuando publiquemos la Política de privacidad actualizada en la página Web</p>
              </div>
        		</div>
        	</div>
        </div>
        <div className="footer-one fixed-bottom">
          <div className="container d-none">
            <div className="footer-content">
              <div className="row col-md-10 offset-md-1 footer-data">
                <div className="footer-item">
                  <h5>buenos aires</h5>
                  <p>Com. Clodomiro Urtubey 2327</p>
                  <p>1430 Buenos Aires (Argentina)</p>
                  <p>buenosaires@agilar.com </p>
                  <p>+54 11 4544 1912 </p>
                  <p>M-F from 8h to 17h</p>
                </div>
                <div className="footer-item gent-item">
                  <h5>gent</h5>
                  <p>Koning Albertlaan 198</p>
                  <p>9000 Gent (Bélgica)</p>
                  <p>gent@agilar.com</p>
                  <p>+32 9 335 4013</p>
                  <p>M-F from 9h to 17h</p>
                </div>
                <div className="footer-item">
                  <h5>madrid</h5>
                  <p>Avenida Manoteras, 32 </p>
                  <p>28050 Madrid (España)</p>
                  <p>madrid@agilar.com</p>
                  <p>+34 917 526 017</p>
                  <p>M-F from 9h to 17h</p>
                </div>
              </div>
              <div className="col-md-8 offset-md-2 social-box">
                <div className="col-md-12">
                  <div className="social-link">
                    <p>¡Síguenos en redes sociales para estar al tanto de nuestras novedades!</p>
                    <a href = "https://twitter.com/" target = "blank" >
                      <img src="/images/twitter.png" className="social-icons" alt="twitter"/>
                    </a>
                    <a href = "https://www.linkedin.com/" target = "blank" >
                      <img src="/images/linkdin.png" className="social-icons" alt="linkdin"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-main">
            <div className="row col-md-10 offset-md-1 footer-main-row">
              <ul className="list-inline">
                <li className="list-inline-item agilar-copyright">
                  &copy;{new Date().getFullYear()} - Agilar
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/terms`} >
                    {t('home.footer.conditions_of_use')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/legal-warning`} >
                    {t('home.footer.legal_notice')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/privacy-policy`} >
                    {t('home.footer.privacy_policy')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/cookies-policy`} >
                    {t('home.footer.cookies_policy')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('common')(Privacy);
