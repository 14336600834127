import React from "react";
import {translate} from 'react-i18next';
import ManagingPartner from "./history/partners/ManagingPartner";

const ManagingPartners = ({t}) => {
    return (
        <div className="section">
            <div className="container section-5">
                <div className="col">
                    <h4 className="header text-uppercase agilar-yellow">{t('about.partners.title')}</h4>
                </div>
                <div className="d-flex flex-wrap justify-content-around">
                    <ManagingPartner name="xavier" linkedin="https://www.linkedin.com/in/xquesada/"/>
                    <ManagingPartner name="tiago" linkedin="https://www.linkedin.com/in/tiago-garcez-575292/"/>

                </div>

            </div>
        </div>

    )
}

export default translate('common')(ManagingPartners);