import React from "react"

import "./Progress.css"
import {translate} from "react-i18next";

const ProgressEntry = ({last, name, active, handleClick, t}) => {
    const key = `about.history.progress.${name}`;
    const containerClass = active ? "circle clickable active" : "circle clickable"
    return (
        <>
            <div className={containerClass} onClick={handleClick}>
                <p className="title">{t(key)}</p>
            </div>
            {
                !last &&
                <span className="bar"/>
            }
        </>

    )
}

export default translate('common')(ProgressEntry);