import React from "react"
import {translate} from "react-i18next";

const ContactMadrid = ({getWorkingHours}) => {
    return (
        <div className="footer-item col-sm-6 col-md-4 col-xl-2 py-sm-2">
            <div className="footer-item-content d-flex flex-column">
                <h5>madrid</h5>
                <p>Avenida Manoteras, 32 </p>
                <p>28050 Madrid (España)</p>
                <a href="mailto:madrid@agilar.com" target="_blank" rel="noopener noreferrer">madrid@agilar.com</a>
                <a href="tel:+34 917 526 017">+34 917 526 017</a>
                <p>{getWorkingHours(9, 17)}</p>
            </div>
        </div>
    )
}

export default translate('common')(ContactMadrid);