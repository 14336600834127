import React from "react"
import "./ClientsComponent.css"
import {translate} from "react-i18next";
import {countryClients, globalClients} from "./clientsList";
import useLocale from "../../componentLibrary/hooks/useLocale";
import ContactForm from "../ContactForm";
import Addresses from "../home/addresses";

const ClientsComponent = ({t}) => {
    const [country, language] = useLocale()

    const currentClients = countryClients[country]

    return (
        <>
            <div className="section container clients-page">
                <div className="col mb-4">
                    <h4 className="page-header text-uppercase agilar-yellow">{t("clients.global.title")}</h4>
                    <p className="text-row text-justify">{t("clients.global.copy")}</p>
                    <div className="row">
                        {globalClients.map(client => (
                            <div className={`client-image-container col-6 col-md-6 col-lg-2`} key={`global-${client}`}>
                                <img className="client-image" src={`/images/clients/${client}.png`} alt={client}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-4 col">
                    <h4 className="page-header text-uppercase agilar-yellow">{t("clients.local.title")}</h4>
                    <p className="text-row text-justify">{t("clients.local.copy")}</p>
                    <div className="row">
                        {currentClients.map(client => (
                            <div className={`client-image-container col-6 col-md-6 col-lg-2`} key={`local-${client}`}>
                                <img className="client-image" src={`/images/clients/${client}.png`} alt={client}/>
                            </div>
                        ))}
                    </div>
                </div>
                <ContactForm/>
            </div>
            <Addresses/>
        </>
    )
}

export default translate('common')(ClientsComponent)
