import React from "react";
import {translate} from "react-i18next";
import useLocale from "../../componentLibrary/hooks/useLocale";
import {withRouter} from "react-router-dom";

const ServerError = ({t, history}) => {
const [country, language] = useLocale();
  const msg = t('errors.serverError');
  const goBack = "Go back"
  return (
      <div className="container training-data error-page">
          <h4 className="training-heading">
              <span className="border-content">{msg.substring(0, 3)}</span>
              {msg.substring(3, msg.length)}&nbsp;
              {goBack}&nbsp;
              <span className="call-to-action" onClick={() => history.push(`/${country}/${language}`)}>HOME</span>
          </h4>
      </div>
  )
}

export default translate('common')(withRouter(ServerError));
