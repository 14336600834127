import React, {Component} from 'react';
import './index.css'
import {translate} from 'react-i18next';
import NoTrainings from "./NoTrainings";
import Category from "./Category";
import axios from "axios"
import {handleServerError} from "../../utils/request";

class Trainings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            categories: [],
        };
    }

    componentDidMount() {
        this.props.setActiveMenu('services');
        const country = localStorage.getItem('country')
        const language = localStorage.getItem('language')
        document.title = "Agilar | " + this.props.t('home.nav.training');
        this.setState({loading: true})
        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/get_categories?language=${language}&country=${country}&published=true`)
            .then(response => {
                this.setState({categories: response.data, loading: false})
            })
            .catch(error => handleServerError(this.props.history, error, country, language))
    }

    render() {
        const language = localStorage.getItem('language')
        const country = localStorage.getItem('country')

        const {loading, categories} = this.state
        const categoriesExist = loading || (!!categories && categories.length > 0);

        return (
            <div className="training-main">
                <div className="training-section">
                    <div className="container">
                        <div className="col-md-12 training-row">
                            <div className="row">
                                {categoriesExist && categories.map(category =>
                                    <Category key={category.id}
                                              category={category}
                                              country={country}
                                              language={language}/>
                                )}
                                {!categoriesExist && <NoTrainings/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
}

export default translate('common')(Trainings);
