import React from "react"
import "./Member.css"

const Member = ({member, className}) => {
    const firstName = member.firstName
    const lastName = member.lastName
    const title = member.jobTitle
    const imgSrc = `${process.env.REACT_APP_ASSETS_HOST}/public/team/${member.name}.png`;
    return (
        <div className={`member-container ${className}`}>
            <div className="member-image-container">
                <img className="member-photo" src={imgSrc} alt={member.name}/>
                <div className="team-overlay">
                    <h5 className="first name">{firstName}</h5>
                    <h5 className="first name">{lastName}</h5>
                    <p className="my-1 job-title">{title}</p>
                    <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                        <img className="linkedin-icon" src="/images/linkedin_link.png"/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Member;