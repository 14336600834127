import React from "react";
import {Link} from "react-router-dom";
import {translate} from "react-i18next";

const NotFound = ({t}) => {
  return (
    <div className="container error-page">
      <h2>{t('errors.notFound')}</h2>
    </div>
  )
}

export default translate('common')(NotFound);
