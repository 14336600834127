import React from "react"
import {translate} from "react-i18next";

const ContactGent = ({getWorkingHours}) => {
    return (
        <div className="footer-item    col-sm-6 col-md-4 col-xl-2 py-sm-2">
            <div className="footer-item-content d-flex flex-column">
                <h5>gent</h5>
                <p>Koning Albertlaan 198</p>
                <p>9000 Gent (Belgium)</p>
                <a href="mailto:gent@agilar.com" target="_blank" rel="noopener noreferrer">gent@agilar.com</a>
                <a href="tel:+32 9 335 4013">+32 9 335 4013</a>
                <p>{getWorkingHours(9, 17)}</p>
            </div>
        </div>
    )
}

export default translate('common')(ContactGent);
