import React, {useEffect, useState} from "react";
import Select from "react-select";
import {translate} from "react-i18next";

const LanguageDropdown = ({languages, setSelectedLanguage, selectedLanguage, t}) => {
    const emptyOption = [{value: null, label: t(`services.course_registration.language.all_languages`)}]
    const [options, setOptions] = useState([])
    useEffect(() => {
        setOptions(emptyOption.concat(languages.map(lang => ({label: t(`services.course_registration.language.${lang}`), value: lang}))))
    }, [languages])

    const style = {
        control: base => ({
            ...base,
            border: 0,
            borderBottom: '2px solid #ffc116',
            borderColor: '#ffc116 !important',
            borderRadius: 0,
            marginRight: '-30px',
            // This line disable the blue border
            boxShadow: "none"
        }),
        indicatorSeparator: () => {}, // removes the "stick"
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#ffc116' // your changes to the arrow
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? '#ffc116 !important' : 'white !important',
            color: state.isFocused ? state.isSelected ? 'black' : '#ffc116' : 'black'


        })
    };

    return (
        <Select className="col-12 col-md-3 offset-md-9"
                styles={style}
                defaultOption={null}
                value={selectedLanguage}
                onChange={(lang) => setSelectedLanguage(lang)}
                placeholder={t('services.courses_list.language')}
                options={options}/>
    )
}

export default translate('common')(LanguageDropdown);