import React from "react"
import "./Certification.css"
import {translate} from "react-i18next";

const Certification = ({name, t}) => {
    const imagePath = `/images/${name}.png`;
    const textKey = `about.certifications.${name}`;

    return (
        <>
            <div className="certification-container col-lg col-6 col-md-4 d-lg-flex flex-column justify-content-center d-none">
                <img src={imagePath} alt={name}/>
                <p className="text-center">{t(textKey)}</p>
            </div>
            <div className="mobile-certification-container col-6 col-md-4 d-flex flex-column align-items-center justify-content-center d-lg-none">
                <img src={imagePath} alt={name}/>
                <p className="text-center">{t(textKey)}</p>
            </div>
        </>
    )
}

export default translate('common')(Certification);