import React, {Component} from 'react'
import {Route} from "react-router-dom";

const MenuRoute = ({ component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={() =>  (
          <Component setActiveMenu={rest.setActiveMenu} />
      )}
    />
  );
};

export default MenuRoute;
