import React from "react"
import styles from "./Newsletter.module.css"

const Newsletter = () => {
    return (
        <iframe className={styles.form}
                src="https://60f9b5ae.sibforms.com/serve/MUIEAPJ-nTUakHFDvD-4WArHfYnoOvzGwWJi0xJ_GsZZrcPyJOlBglsNGZ8OzawpZb5-_KmbL9LsOXJswBBH8R01kLUYW6Y5JLHEjSpzArqhvIZf8rEhBzTEmIW__LA7MLvOwbMWfv9-2zUfVjaZmxWSxLUVmdkqloRRb0Rcxf46ZJ1Qkn6N-HUPfNgX6jTSPyhTsl_3lPu480Wy"
                frameBorder="0"
                scrolling="auto"
                allowFullScreen
                style={{display: 'block', marginLeft: 'auto',marginRight: 'auto', maxWidth: '100%'}}/>
    )
}

export default Newsletter;
