import React from "react"
import {translate} from "react-i18next";
import useLocale from "../../../../componentLibrary/hooks/useLocale";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import InputWrapper from "../../../../componentLibrary/InputWrapper";
import axios from "axios";
import {handleServerError} from "../../../../utils/request";
import {useHistory} from "react-router-dom";

const PrivateForm = ({ training, onSuccess, t }) => {

    const [country, language] = useLocale();
    const history = useHistory()

    const onSubmit = (values, {setSubmitting}) => {
        const postData = {
            registration: {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                contact_phone: values.phone,
                company: values.company,
                streetname_and_number: values.street,
                city_and_postal_code: values.city,
                country: values.country,
                document_number: values.idNumber,
                reduction_code: values.registrationCode,
                comments: values.comments,
                payer_type: 'private',
            },
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/v2/courses/${training.id}/register`, postData)
            .then(response => {
                setSubmitting(false)
                onSuccess(postData.registration)
            })
            .catch(error => handleServerError(history, error, country, language))
    }

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        street: "",
        city: "",
        country: "",
        idNumber: "",
        registrationCode: "",
        comments: ""
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('register.validation.firstName')),
        lastName: Yup.string().required(t('register.validation.lastName')),
        email: Yup.string().email("Please enter a valid email").required(t('register.validation.emailAddress')),
        phone: Yup.string(),
        company: Yup.string(),
        street: Yup.string().required(t('register.validation.street')),
        city: Yup.string().required(t('register.validation.city')),
        country: Yup.string().required(t('register.validation.country')),
        idNumber: Yup.string().required(t('register.validation.documentNumber')),
        registrationCode: Yup.string(),
        comments: Yup.string()
    })

    return (
        <>
            <h5 className="pl-4 pt-4 pb-3">{t('services.course_registration.participant_information')}</h5>
            <Formik onSubmit={onSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}>
                {({isSubmitting}) =>
                    <Form>
                        <div className="form-row">
                            <InputWrapper name="firstName"
                                          label={t('services.registration_contact_us.first_name')}
                                          containerClass="col-md-6 px-sm-4"
                                          isRequired/>
                            <InputWrapper name="lastName"
                                          label={t('services.registration_contact_us.last_name')}
                                          containerClass="col-md-6 px-sm-4"
                                          isRequired/>
                            <InputWrapper name="email"
                                          label={t('services.registration_contact_us.email_address')}
                                          containerClass="col-md-4 px-sm-4"
                                          isRequired/>
                            <InputWrapper name="phone"
                                          label={t('services.registration_contact_us.phone_no')}
                                          containerClass="col-md-4 px-sm-4"/>
                            <InputWrapper name="company"
                                          label={t('services.registration_contact_us.company')}
                                          containerClass="col-md-4 px-sm-4"/>
                        </div>

                        <h5 className="pl-4 pt-4 pb-3">{t('services.course_registration.invoicing_information')}</h5>
                        <div className="form-row">
                            <InputWrapper name="street"
                                          label={t('services.course_registration.streetname_and_number')}
                                          containerClass="col-md-6 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper name="city"
                                          label={t('services.course_registration.city_and_postal_code')}
                                          containerClass="col-md-6 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper name="country"
                                          label={t('services.course_registration.country')}
                                          containerClass="col-md-4 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper name="idNumber"
                                          label={t('services.course_registration.document_number')}
                                          containerClass="col-md-4 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper name="registrationCode"
                                          label={t('services.course_registration.registration_code')}
                                          containerClass="col-md-4 px-sm-4 mb-5"/>
                            <InputWrapper name="comments"
                                          label={t('services.course_registration.comments')}
                                          containerClass="col-md-12 px-sm-4 mb-5"/>
                        </div>
                        <button className="btn yellow-btn register-btn mt-2 ml-4"
                                type="submit"
                                disabled={isSubmitting}>
                            {training.full ? t('services.courses_list.waiting_list') : t('services.courses_list.register_now')}
                        </button>
                    </Form>
                }
            </Formik>
        </>
    )

}

export default translate('common')(PrivateForm);
