import React from "react"
import {translate} from "react-i18next";
import useLocale from "../../../../componentLibrary/hooks/useLocale";
import * as Yup from "yup"
import {Form, Formik} from "formik";
import CheckboxWrapper from "../../../../componentLibrary/CheckboxWrapper";
import CompanyStudents from "./CompanyStudents";
import InputWrapper from "../../../../componentLibrary/InputWrapper";
import CompanyContact from "./CompanyContact";
import axios from "axios";
import {handleServerError} from "../../../../utils/request";
import {useHistory} from "react-router-dom";

export const EMPTY_STUDENT = () => ({tempId: Date.now() ,firstName: "", lastName: "", email: ""})

const CompanyForm = ({ training, onSuccess, t }) => {

    const [country, language] = useLocale();
    const history = useHistory()

    const onSubmit = (values, {setSubmitting}) => {
        const postData = {
            students: values.students.map(s => ({
                first_name: s.firstName,
                last_name: s.lastName,
                email: s.email
            })),
            registration: {
                send_email_to_all_registrants: values.sendEmail,
                company: values.company,
                billing_name: values.billingName,
                streetname_and_number: values.street,
                city_and_postal_code: values.city,
                country: values.country,
                vat_number: values.vatNumber,
                contact_person: values.contactPerson,
                contact_email: values.contactEmail,
                contact_phone: values.contactPhone,
                payer_type: 'business',
                commennts: values.comments,
                reduction_code: values.reductionCode
            },
        }

        axios.post(`${process.env.REACT_APP_API_URL}/api/v2/courses/${training.id}/register`, postData)
            .then(response => {
                setSubmitting(false)
                onSuccess(postData.registration)
            })
            .catch(error => handleServerError(history, error, country, language))
    }

    const initialValues = {
        sendEmail: false,
        students: [EMPTY_STUDENT()],
        company: "",
        billingName: "",
        street: "",
        city: "",
        country: "",
        vatNumber: "",
        contactPerson: "",
        contactPhone: "",
        contactEmail: "",
        comments: "",
        reductionCode: ""
    }

    const validationSchema = Yup.object({
        students: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required(t('register.validation.firstName')),
                lastName: Yup.string().required(t('register.validation.lastName')),
                email: Yup.string().required(t('register.validation.emailAddress'))
            })),
        sendEmail: Yup.boolean(),
        company: Yup.string().required(t('register.validation.company')),
        billingName: Yup.string().required(t('register.validation.billingName')),
        street: Yup.string().required(t('register.validation.street')),
        city: Yup.string().required(t('register.validation.city')),
        country: Yup.string().required(t('register.validation.country')),
        vatNumber: Yup.string().required(t('register.validation.vatNumber')),
        contactPerson: Yup.string().required(t('register.validation.contactPerson')),
        contactEmail: Yup.string().required(t('register.validation.contactEmail')),
        contactPhone: Yup.string().required(t('register.validation.contactPhone')),
    })

    return (
        <>
            <h5 className="pl-3 pt-4 pb-3">{t('services.course_registration.trainees')}</h5>
            <Formik onSubmit={onSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}>
                {({ isSubmitting }) =>
                    <Form>
                        <CheckboxWrapper containerClass="px-sm-4" name="sendEmail">
                            {t('services.course_registration.students_also_receive')}
                        </CheckboxWrapper>
                        <CompanyStudents/>
                        <InputWrapper id="company"
                                      name="company"
                                      label={t('services.registration_contact_us.company')}
                                      containerClass="col-md-12 px-sm-4"
                                      isRequired/>
                        <h5 className="pl-4 pt-4 pb-3">{t('services.course_registration.invoicing_information')}</h5>
                        <div className="form-row">
                            <InputWrapper id="billingName"
                                          name="billingName"
                                          label={t('services.course_registration.billing_name')}
                                          containerClass="col-md-4 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper id="street"
                                          name="street"
                                          label={t('services.course_registration.streetname_and_number')}
                                          containerClass="col-md-4 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper id="city"
                                          name="city"
                                          label={t('services.course_registration.city_and_postal_code')}
                                          containerClass="col-md-4 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper id="country"
                                          name="country"
                                          label={t('services.course_registration.country')}
                                          containerClass="col-md-6 px-sm-4 mb-5"
                                          isRequired/>
                            <InputWrapper id="vatNumber"
                                          name="vatNumber"
                                          label={t('services.course_registration.vatNumber')}
                                          containerClass="col-md-6 px-sm-4 mb-5"
                                          isRequired/>
                        </div>
                        <CompanyContact/>
                        <div className="form-row">
                            <InputWrapper id="comments "
                                          name="comments"
                                          label={t('services.course_registration.comments')}
                                          containerClass="col-md-8 px-sm-4 mb-5"/>
                            <InputWrapper id="reductionCode "
                                          name="reductionCode"
                                          label={t('services.course_registration.registration_code')}
                                          containerClass="col-md-4 px-sm-4 mb-5"/>
                        </div>
                        <button className="btn yellow-btn register-btn mt-2 ml-4"
                                type="submit"
                                disabled={isSubmitting}>
                            {t('services.courses_list.register_now')}
                        </button>
                    </Form>
                }
            </Formik>
        </>
    )

}

export default translate('common')(CompanyForm);
