import React from "react"
import {translate} from 'react-i18next';
import "./History.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import Origins from "./tabs/Origins"
import Transformations from "./tabs/Transformations";
import Csts from "./tabs/Csts";
import Agilar2 from "./tabs/Agilar2";
import Offices from "./tabs/Offices";
import Progress from "./progress/Progress";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleRight, faChevronCircleLeft} from '@fortawesome/free-solid-svg-icons'

class History extends React.Component {
    state = {
        currentSlide: 0,
        slides: [
            'origins',
            'transformations',
            'csts',
            'agilar2',
            'offices'
        ]
    }

    goLeft = () => {
        const {currentSlide} = this.state
        if (currentSlide === 0) return;
        this.setState({currentSlide: currentSlide - 1})
    }

    goRight = () => {
        const {currentSlide} = this.state
        if (currentSlide === 4) return;
        this.setState({currentSlide: currentSlide + 1})
    }


    changeSlide = (number) => {
        this.setState({currentSlide: number})
    }

    render() {
        const {slides, currentSlide} = this.state;
        const imageSrc = `${process.env.REACT_APP_ASSETS_HOST}/public/history/history-${currentSlide}.png`;
        return (
            <div className="section section-4">
                <div className="container">
                    <div className="col">
                        <div className="history-container d-flex flex-column"
                             style={{marginTop: '140px', position: 'relative'}}>
                            <FontAwesomeIcon className="right-icon clickable" onClick={this.goRight}
                                             icon={faChevronCircleRight}/>
                            <FontAwesomeIcon className="left-icon clickable" onClick={this.goLeft}
                                             icon={faChevronCircleLeft}/>

                            <Carousel className="flex-grow-1"
                                      selectedItem={this.state.currentSlide}
                                      showThumbs={false}
                                      showStatus={false}
                                      showArrows={false}
                                      showIndicators={false}>
                                <Origins/>
                                <Transformations/>
                                <Csts/>
                                <Agilar2/>
                                <Offices/>
                            </Carousel>
                            <Progress entries={slides}
                                      activeEntry={currentSlide}
                                      handleEntryClick={this.changeSlide}/>
                            <img className="d-none d-lg-block" src={imageSrc} alt="history"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default translate('common')(History);