const globalClients = [
    "roche",
    "cocacola",
    "bbva",
    "hp",
    "ey",
    "santander",
    "toyota",
    "telefonica",
    "ebay",
    "bainAndCompany",
    "nestle",
    "audivw"
]

const countryClients = {
    be: [
        "abinbev",
        "bnp",
        "bpost",
        "proximus",
        "unilin",
        "tower",
        "baxter",
        "gsk",
        "johnson",
        "fluxys",
        "tui",
        "tomtom",
        "bainAndCompany",
        "swift",
        "innogy",
        "toyota",
        "amadeus",
        "ea",
        "ey",
        "agfa",
        "kapsch",
        "degroof",
        "siemens",
        "bd",
        "ebay",
        "numeca",
        "bru",
        "gft",
        "zetes",
        "hedera"
    ],
    ar: [
        "molinos",
        "cablevision",
        "natura",
        "fravega",
        "sancristobal",
        "chevron",
        "zonajobs",
        "ejesa",
        "edet",
        "technisys",
        "ball",
        "asociart",
        "ey",
        "bainAndCompany",
        "osde",
        "cocacola",
        "telefonica",
        "itba",
        "bgh",
        "zonaprop",
        "xapo",
        "eventbrite",
        "johndeere",
        "roche",
        "santander",
        "spring",
        "accusys",
        "banco_supervielle",
        "oracle"
    ],
    es: [
        "axa",
        "king",
        "tempe",
        "endesa",
        "accenture",
        "puig",
        "nestle",
        "helvetia",
        "eci",
        "everis",
        "gd",
        "bbva",
        "laliga",
        "roche",
        "vueling",
        "telepizza",
        "gestamp",
        "deloitte",
        "audivw",
        "iberdraola",
        "hp",
        "massimoDutti",
        "mediaset",
        "axpo",
        "telefonica",
        "grupoAsv",
        "vegalsa",
        "gigigo",
        "ferrer",
        "eurecat"
    ]


}

export {
    globalClients,
    countryClients
}
