import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {translate} from 'react-i18next';
import '../index.css';
import axios from "axios";
import CourseDescription from "../CourseDescription";
import CoursePrice from "../CoursePrice";
import useLocale from "../../../componentLibrary/hooks/useLocale";
import {handleServerError} from "../../../utils/request";
import {RoutingConstants} from "../../../utils/routingConstants";
import PastCourse from "./PastCourse";

const CourseDetail = ({setActiveMenu, t}) => {
    const [training, setTraining] = useState({name: ""})
    const [loading, setLoading] = useState(true)

    let { id, name } = useParams()
    if (!id) {
        id = name.split('-')[0]
    }
    const history = useHistory()
    const [ country, language ] = useLocale()

    useEffect(() => {
        setActiveMenu('services')
        document.title = "Agilar | " + t('home.title.course_detail');
        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/courses/${id}`)
            .then(response => {
                setTraining(response.data)
                setLoading(false)
            })
            .catch(error => handleServerError(history, error, country, language))

    }, [id, setActiveMenu])

    let pastCourse = false
    if (!loading && training) {
        const startDate = new Date(training.start_date)
        const today = new Date()
        pastCourse = today >= startDate
    }

    if (pastCourse) {
        return <PastCourse/>
    }

    return (
        <div className="training-description">
            <div className="container">
                <div className="col-md-12">
                    <Link to="#0" onClick={() => history.goBack()}>
                        &#x3c;&nbsp;{t('services.courses_list.back_to_all_courses')}
                    </Link>
                    <div className="row mx-0">
                        <div className="col-md-9">
                            <div className="description-box">
                                <div className="description-head">
                                    <h4>{training.name}, {training.training_date}</h4>
                                    <img src={`/images/trainings/${training.category}.png`} alt="TR"/>
                                </div>
                                <CourseDescription description={training.description}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="description-right">
                                <h5>{t('services.courses_list.schedule')}</h5>
                                <p className="mb-0">{training.training_schedule_date}</p>
                                <p>{training.schedule}</p>
                                <h5>{t('services.courses_list.location')}</h5>
                                <p>{training.city}</p>
                                <h5>{t('services.courses_list.language')}</h5>
                                <p>{t(`services.course_registration.language.${training.language}`)}</p>
                                <h5>{t('services.courses_list.trainer')}</h5>
                                <div className="trainee-profile">
                                    <img src={training.trainer_image} alt="tr"/>
                                    <p>{training.trainer}</p>
                                </div>
                                {training.co_trainer &&
                                <div>
                                    <h5>{t('services.courses_list.co_trainer')}</h5>
                                    <div className="trainee-profile">
                                        <img src={training.co_trainer_image} alt="tr"/>
                                        <p>{training.co_trainer}</p>
                                    </div>
                                </div>
                                }
                                <h5>{t('services.courses_list.price')}</h5>
                                <CoursePrice course={training}/>
                                <div className="trainee-btn">
                                    <Link to={RoutingConstants.TRAINING_REGISTER(training)}
                                          className="btn yellow-btn btn-training mt-4">
                                        {t('services.courses_list.register_now')}
                                    </Link>
                                </div>
                                <div className="more-info">
                                    <h6>{t('services.course_registration.do_you_need_more')}</h6>
                                    <Link to={RoutingConstants.TRAINING_CONTACT(training)}>
                                        {t('services.courses_list.contact_us')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default translate('common')(CourseDetail);
