import React, {useState, useEffect} from "react"
import useLocale from "../../componentLibrary/hooks/useLocale";
import axios from "axios";
import {handleServerError} from "../../utils/request";
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import UpcomingCourses from "../../components/courses/UpcomingCourses";
import LanguageDropdown from "../../components/LanguageDropdown";

const UpcomingTrainingsContainer = ({setActiveMenu, t, history}) => {
    const [loading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([null, null, null, null, null])
    const [country, language] = useLocale();


    useEffect(() => {
        setActiveMenu('upcoming');
        document.title = "Agilar | " + t('home.nav.courses');

        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/courses`)
            .then(response => {
                setTrainings(response.data)
                setLoading(false)
            })
            .catch(error => handleServerError(history, error, country, language))
    }, [])

    return (
        <>
            <UpcomingCourses trainings={trainings} loading={loading}/>
        </>
    )
}

export default translate('common')(withRouter(UpcomingTrainingsContainer));