import React from "react"
import Member from "./Member";
import EmptyMember from "./EmptyMember";

const AgilarMemberList = ({members}) => (
    <>
        <div className="d-flex flex-wrap justify-content-between">
            {members.slice(0, 7).map(member => <Member key={member.name} member={member}/>)}
        </div>
        <div className="d-flex justify-content-between">
            {members.slice(7, 13).map(member => <Member key={member.name} member={member}/>)}
            <EmptyMember/>
        </div>
        <div className="d-flex justify-content-between">
            {members.slice(13, 17).map(member => <Member key={member.name} member={member}/>)}
            <EmptyMember/>
            <EmptyMember/>
            <EmptyMember/>
        </div>
        <div className="d-flex justify-content-between">
            {members.slice(17, 18).map(member => <Member key={member.name} member={member}/>)}
            <EmptyMember/>
            <EmptyMember/>
            <EmptyMember/>
            <EmptyMember/>
            <EmptyMember/>
            <EmptyMember/>
        </div>
    </>
)

export default AgilarMemberList;