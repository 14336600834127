import React from "react"
import {translate} from "react-i18next";

const ContactBuenosAires = ({getWorkingHours}) => {
    return (
        <div className="footer-item col-sm-6 col-md-4 col-xl-2 py-sm-2">
            <div className="footer-item-content d-flex flex-column">
                <h5>buenos aires</h5>
                <p>Com. Clodomiro Urtubey 2327</p>
                <p>1430 Buenos Aires (Argentina)</p>
                <a href="mailto:buenosaires@agilar.com" target="_blank" rel="noopener noreferrer">buenosaires@agilar.com</a>
                <a href="tel:+54 11 4544 1912">+54 11 4544 1912</a>
                <p>{getWorkingHours(8, 17)}</p>
            </div>
        </div>
    )
}

export default translate('common')(ContactBuenosAires);