
export class RoutingConstants {
    static baseUrl = () => `/${localStorage.getItem('country')}/${localStorage.getItem('language')}`

    static TRAINING_REGISTER = (training) => `${RoutingConstants.baseUrl()}/courses/${training.id}/register`

    static TRAINING_CONTACT = (training) => `${RoutingConstants.baseUrl()}/courses/${training.id}/contact`

   static UPCOMING_TRAININGS = () => `${RoutingConstants.baseUrl()}/courses/upcoming`
}
