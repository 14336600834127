import React from "react"
import "./Member.css"

const EmptyMember = () => {
    return (
        <div className="member-container empty">
        </div>
    )
}

export default EmptyMember;