import React from "react";
import {translate} from 'react-i18next';
import CountUp from "react-countup";

const WeAreAgilar = ({t}) => {
    return (
        <div className="section section-1">
            <div className="container">
                <div className="col">
                    <div>
                        <h4 className="header text-uppercase agilar-yellow">We are agilar</h4>
                    </div>
                </div>
                <p className="mt-4 col text-justify text-row">{t('about.section1.line1')}</p>
                <p className="col text-justify text-row">{t('about.section1.line2')}</p>
            </div>
            <div className="container-fluid agilar-info">
                <div className="container">
                    <div className="row align-content-center">
                        <div className="info-bit col-lg-3 my-lg-4 col-xs-12 col-md-6">
                            <span><CountUp start={10500} end={10716} duration={3}/></span>
                            <span>{t('home.who_we_are.info.students.line_1')}</span>
                            <span>{t('home.who_we_are.info.students.line_2')}</span>
                        </div>
                        <div className="info-bit col-lg-3 my-lg-4 col-xs-12 col-md-6">
                            <span><CountUp start={0} end={329} duration={3}/></span>
                            <span>{t('home.who_we_are.info.public.line_1')}</span>
                            <span>{t('home.who_we_are.info.public.line_2')}</span>
                        </div>
                        <div className="info-bit col-lg-3 my-lg-4 col-xs-12 col-md-6">
                            <span><CountUp start={0} end={324} duration={3}/></span>
                            <span>{t('home.who_we_are.info.private.line_1')}</span>
                            <span>{t('home.who_we_are.info.private.line_2')}</span>
                        </div>
                        <div className="info-bit col-lg-3 my-lg-4 col-xs-12 col-md-6">
                            <span><CountUp start={7500} end={7913} duration={3}/></span>
                            <span>{t('home.who_we_are.info.coffee.line_1')}</span>
                            <span>{t('home.who_we_are.info.coffee.line_2')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default translate('common')(WeAreAgilar);