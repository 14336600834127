export const COUNTRY_TO_LANGUAGE_MAP = {
	'ar': 'ar',
	'es': 'es',
	'be': 'en'
};

export const PREFERRED_COUNTRY_KEY = "PREFERRED_COUNTRY";

export const CLIENT_LOGOS = {
	es: [
		{
			name: 'nestle',
			image: '/images/nestl.png',
			overlayText: 'Formaciones in-house CSM® y CSPO® como parte de la iniciativa de implantación de agilidad en Nestlé Digital Hub'
		},
		{
			name: 'king',
			image: '/images/jking.png',
			overlayText: 'Coaching de equipos ágiles para la puesta en marcha de agile en la creación de videojuegos y formaciones in-house certificadas'
		},
		{
			name: 'tempe',
			image: '/images/Tempe.png',
			overlayText: ' Coaching a equipos de Product Owners y consultoría de desarrollo'
		},
		{
			name: 'bbva',
			image: '/images/bbva.png',
			overlayText: 'Formación para la implementación de Scrum en equipos ágiles del área digital de BBVA'
		},
		{
			name: 'accenture',
			image: '/images/accenture.png',
			overlayText: 'Más de 500 alumnos certificados en las formaciones CSM® y CSPO®'
		},
		{
			name: 'puig',
			image: '/images/puig.png',
			overlayText: 'Capacitación a equipos piloto y experimentación de modelos ágiles para el kick-off de proyectos agile'
		},
		{
			name: 'axa',
			image: '/images/axa.png',
			overlayText: 'Más de un centenar de alumnos certificados en formaciones públicas'
		},
		{
			name: 'helvetica',
			image: '/images/logo-helvetia.png',
			overlayText: ' Acompañamiento en la transformación ágil mediante sesiones de coaching con foco en el departamento de desarrollo informático y sus stakeholders principales'
		}
	],
	be: [
		{
			name: 'abinbev',
			image: '/images/abinbev.png',
			overlayText: 'Providing support to the agile transformation through in-house CSPO® trainings, organizational agility coaching, and maturity assessment'
		},
		{
			name: 'numeca',
			image: '/images/numeca.png',
			overlayText: 'Guiding the company\'s Scrum Master with our organizational agility coaching'
		},
		{
			name: 'bpost',
			image: '/images/bpost.png',
			overlayText: 'Working with leadership in adopting a Scrum@Scale approach for handling a strategic program impacting mostly non-software areas'
		},
		{
			name: 'proximus',
			image: '/images/proximus.png',
			overlayText: 'Engaged with leadership in the web domain to help their teams adopt a Scrum approach to delivery + continuous improvement'
		},
		{
			name: 'unilin',
			image: '/images/unilin.png',
			overlayText: 'Coaching in their Digital Transformation. Introducing mixed Scrum teams with Business and IT people for developing their B2B and B2C online presence and introducing Scrum in operations'
		},
		{
			name: 'tower',
			image: '/images/tower.png',
			overlayText: '\tSupporting factory leaders in adopting Scrum for their continuous improvement teams'
		},
	],
	ar: [
		{
			name: 'telefonica',
			image: '/images/telefonica.png',
			overlayText: 'Coaching a coaches de la transformación digital de la compañía'
		},
		{
			name: 'telecom',
			image: '/images/telecom.png',
			overlayText: 'Capacitación y acompañamiento de equipos'
		},
		{
			name: 'natura',
			image: '/images/natura.jpg',
			overlayText: 'Capacitación y acompañamiento a los integrantes del Centro Operativo de Excelencia Latam'
		},
		{
			name: 'cocacola',
			image: '/images/cocacola.png',
			overlayText: 'Capacitación a personal de planta de producción de concentrado'
		},
		{
			name: 'sancristobal',
			image: '/images/sancristobal.png',
			overlayText: 'Inducción al agilismo para ingresantes y personal de la empresa'
		},
		{
			name: 'chevron',
			image: '/images/chevron.png',
			overlayText: 'Capacitación de personal de Portfolio & Project Management Center of Excellence (Buenos Aires Shared Services Center)'
		},
		{
			name: 'navent',
			image: '/images/navent.png',
			overlayText: 'Acompañamiento a las unidades de Jobs y Real Estate'
		},
		{
			name: 'banco',
			image: '/images/banco_supervielle.png',
			overlayText: 'Acompañamiento de equipos en la transformación digital'
		},

	]
};

export const baseUrl = () => {

	const burl = `/${localStorage.getItem('country')}/${localStorage.getItem('language')}`
	console.log('BASE URL', burl)

	return `/${localStorage.getItem('country')}/${localStorage.getItem('language')}`
}
