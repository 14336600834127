import React from "react"
import {Link} from "react-router-dom";
import {translate} from "react-i18next";
import CourseBody from "./CourseBody";
import Skeleton from "react-loading-skeleton";

const Course = ({course, country, language}) => {
    const imagePath = course ? course.image_url ? course.image_url : `/images/trainings/${course.category}.png` : null;
    const title = course ? `${course.name}, ${course.training_date}` : null;
    const courseAbstract = course ? course.abstract : null;
    return (
        <div className="row courses-box">
            <div className="col-md-2 d-flex flex-column justify-content-center">
                {
                    imagePath ?
                    <img src={imagePath} className="courses-img" alt="TR"/> :
                        <Skeleton circle={true} height={100} width={100}/>

                }
            </div>
            <div className="col-md-10">
                <div className="content-head">
                    {title ?
                        <Link to={`/${country}/${language}/courses/${course.id}`}>{title}</Link> :
                        <Skeleton/>
                    }
                </div>
                <h6 className="py-2">{courseAbstract || <Skeleton count={2}/>}</h6>
                {
                    course ?
                    <CourseBody course={course} country={country} language={language}/> :
                    <Skeleton count={3}/>
                }
            </div>
        </div>
    )
}

export default translate('common')(Course);
