import React from "react"
import TextareaAutosize from 'react-textarea-autosize';

const ContactTextarea = ({id, name, value, onChange, placeholder, className, disabled, required})=> {

    return (
        <TextareaAutosize
            id={id}
            className={className}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            required={required}
            name={name}/>
    )
}

export default ContactTextarea
