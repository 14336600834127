import React from "react";
import {translate} from "react-i18next";

const Transformations = ({t}) => {
    return (
        <div className="history-tab">
            <h4 className="header">{t('about.history.transformations.header')}</h4>
            <p>{t('about.history.transformations.line1')}</p>
            <p>{t('about.history.transformations.line2')}</p>
            <p>{t('about.history.transformations.line3')}</p>
        </div>
    )
}

export default translate('common')(Transformations);