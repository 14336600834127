import React, {Component} from 'react';
import './AboutAgilar.css'
import {translate} from 'react-i18next';
import History from "./history/History";
import Team from "./history/team/Team";
import WeAreAgilar from "./WeAreAgilar";
import WeImproveMondays from "./WeImproveMondays";
import Certifications from "./Certifications";
import ReactFullpage from "@fullpage/react-fullpage";
import ManagingPartners from "./ManagingPartners";

class AboutAgilar extends Component {

    componentDidMount() {
        this.props.setActiveMenu('about');
        const {t} = this.props;
        document.title = "Agilar | " + t('home.nav.agilar');
    }

    render() {
        const {t} = this.props;
        return (
            <ReactFullpage
                sectionsColor={['#fff', '#fff', '#fff']}
                slideSelector={'.not-slide'}
                scrollOverflow = {true}
                anchors={['agilar', 'mondays', 'certifications', 'history', 'partners', 'team']}
                render={({state, fullpageApi}) => {
                    return (
                        <div className="about-agilar-main">
                            <WeAreAgilar/>
                            <WeImproveMondays/>
                            <Certifications/>
                            <History/>
                            <ManagingPartners/>
                            <Team/>
                        </div>
                    );
                }}
            />)
    }
}

export default translate('common')(AboutAgilar);
