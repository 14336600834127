import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { translate } from 'react-i18next';

class Terms extends Component {

  componentDidMount(){
    const { t } = this.props;
    document.title = "Agilar | "+ t('home.footer.conditions_of_use');
  }

  render () {
    const i8n_language = localStorage.getItem('language') || 'es'
    const country = localStorage.getItem('country') || 'es'

    const { t } = this.props;
    return (
      <div>
        <div className="terms-main">
        	<div className="terms-section">
        		<div className="container">
        			<div className="col-md-12">
                <h6 className="text-center">Condiciones de Uso del sitio Web.</h6>
              </div>
              <div className="col-md-10 offset-md-1">
                <li>1.- Objeto y contenido del sitio web.</li>
                  <p>Las presentes condiciones generales de uso regulan el acceso y utilización de  <a href="# ">www.agilar.es</a> en adelante el "Sitio Web", cuyo contenido tiene como objetivo establecer un canal de comunicación entre el titular de la Web y los visitantes del sitio Web, respecto a las actividades del titular, servicios que pone a disposición y la información que proporciona.</p>
                  <p>La consulta de los contenidos del Sitio Web no constituirá por sí misma ningún tipo de relación contractual y su utilización es responsabilidad exclusiva del usuario. La información incluida en el sitio web, especialmente en cualquiera de los newsletters, no constituye en ningún caso un asesoramiento y su utilización es responsabilidad exclusiva del usuario</p>
                  <p>El titular del sitio Web, no será en ningún caso responsable de los errores u omisiones que pudieran existir en la información facilitada, a excepción de aquellos extremos a los que obligue la Ley, ni de la aplicación o uso concreto que pudiera hacerse de la misma.</p>
                  <p><b className="highlighted-text">Agilar Spain S.L.,</b> en adelante <b className="highlighted-text">AGILAR</b> se reserva el derecho exclusivo a actualizar, modificar o eliminar los contenidos del Sitio Web, de las presentes condiciones de uso, de la política de privacidad y de cualquier advertencia legal específica, que puedan resultar aplicables a la presente Web; así como el derecho de limitar o impedir el acceso a los mismos. Se podrá ejercer tales derechos en cualquier momento y sin previo aviso.</p>
                <li>2.- Propiedad Intelectual.</li>
                  <p>Quedan reservados en favor del titular de la Web www.agilar.es todos los derechos sobre los contenidos -textuales y gráficos- tanto de la página web como de las redes sociales que aparecen en la misma.</p>

                  {/*
                    <p>Queda prohibido cualquier uso de los contenidos de sitio web concretamente, sobre las imágenes, diseño, obras, marcas, logotipos, código fuente y cualquier otro susceptible de protección, sin la autorización expresa del titular, salvo la posibilidad de compartir contenidos cuando <b className="highlighted-text">AGILAR</b> así lo autorice. </p>
                    <p>El titular del sitio Web no concede ningún tipo de licencia o autorización de uso al Usuario sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho relacionado con la página Web y los servicios ofrecidos en la misma.</p>
                    <p>Por ello, el Usuario reconoce que la reproducción, distribución, comercialización, transformación, y en general, cualquier otra forma de explotación, por cualquier procedimiento, de todo o parte de los contenidos de este sitio Web constituye una infracción de los derechos de propiedad intelectual, pudiendo dar lugar al ejercicio por parte del titular de la Web, de todas las acciones judiciales o extrajudiciales que pudieran corresponder en ejercicio de sus derechos.</p>
                  */}

                <li>3.- Protección de Datos.</li>
                  <p>AGILAR en calidad de responsable-titular del sitio Web y en cumplimiento de la normativa vigente en Protección de Datos de Carácter Personal, concretamente el Reglamento 2016/679 del parlamento europeo y del consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (En adelante, RGPD), que señala que el consentimiento para el tratamiento de datos debe ser expreso, “exigiendo que se manifieste de forma clara e inequívoca por parte del interesado a través de un acto afirmativo claro que evidencie una declaración de voluntad libre, específica, informada e inequívoca del interesado de admitir el tratamiento de datos de carácter personal que le afectan”, le informamos que los datos personales que usted nos facilite voluntariamente serán incluidos en un registro de actividades, creado bajo nuestra responsabilidad, con la finalidad de poder gestionar sus peticiones y/o consultas, asimismo enviarle información sobre actividades o servicios relacionados con los servicios descritos en esta página Web y en su caso prestarle los servicios que nos sean solicitados.</p>
                  <p>Los datos (*) son obligatorios para tratar y llevar a cabo las solicitudes. El resto de datos solicitados, cuya respuesta es opcional, están destinados a conocerte mejor, además de mejorar los servicios propuestos, asegurando en todo momento la privacidad. Salvo revocación por tu parte, AGILAR podrá enviarte por correo electrónico información sobre el sitio, las próximas fechas de realización de diferentes servicios ofertados a través de nuestra Web. Tus datos pueden comunicarse a las empresas del grupo descritas en la política de privacidad.</p>
                  <p>Ud. podrá ejercer los derechos de acceder, rectificar, limitar, cancelar y solicitar la portabilidad de los datos dirigiendo a tal efecto un escrito (acompañando copia de un documento oficial que le identifique) a nuestro domicilio social, Avenida de Manoteras 32, 28050 Madrid o a <a href="# ">info@agilar.org</a></p>
                  <p>El responsable del tratamiento se compromete a observar la obligación de secreto de los datos de carácter personal que nos facilite, a tratarlos de forma confidencial y a guardarlos adoptando las medidas necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizados, habida cuenta en todo momento del estado de la tecnología, cumpliendo las previsiones establecidas en el RGPD.</p>
                  <p>Los usuarios que proporcionen sus datos personales para cualquiera de las funcionalidades de la página web, deberán aceptar la <Link to={`/${country}/${i8n_language}/privacy-policy`} >
                    {t('home.footer.privacy_policy')}
                  </Link></p>
                <li>4.- Redes sociales.</li>
                  <p>Ponemos en su conocimiento que estamos presentes en Redes Sociales. El tratamiento de los datos que se realice de las personas que se hagan seguidoras (y/o realicen cualquier vínculo o acción de conexión a través de las Redes Sociales) de las páginas oficiales de AGILAR en las redes sociales, se regirá por este apartado, así como por aquellas Condiciones de uso, políticas<br/>
                  de privacidad y demás normativas de acceso, uso y similares que pertenezcan a la red social que corresponda, de las que el usuario de las Redes Sociales ya habrá aceptado. AGILAR tratará sus datos con las finalidades de administrar correctamente su presencia en la red social que corresponda, informarlo de actividades productos o servicios de AGILAR o de terceros que puedan estar relacionados con nuestra actividad así como para cualquier otra finalidad que las normativas de las Redes Sociales puedan permitir.</p>
                <li>5.- Publicidad.</li>
                  <p>El sitio Web podrá alojar contenidos publicitarios o patrocinados. Los anunciantes o patrocinadores son los únicos responsables de asegurarse que el material remitido para su inclusión en el sitio Web cumple con las leyes que en cada caso puedan ser de aplicación.</p>
                  <p>AGILAR no será responsable, en ningún caso, de los contenidos publicitarios o de los patrocinadores incluidos en el sitio Web.</p>
                <li>6.- Enlaces.</li>
                  <p>AGILAR no se hace responsable de los contenidos a los que se dirigen los enlaces ubicados en el sitio Web, en su caso, según lo establecido en el artículo 17 de la Ley 34/2002, de fecha 11 de julio (LSSI). El Usuario es el encargado de aceptar y comprobar los accesos cada vez que acceda.</p>
                  <p>Tales enlaces o menciones tienen una finalidad que no implican el apoyo, la aprobación, comercialización o relación alguna entre la página y las personas o entidades titulares de los lugares donde se encuentren.</p>
                  <p>Los terceros que tengan la intención de incluir en una página web un enlace de la presente página web tendrán que cumplir con la legislación vigente y no podrán alojar contenidos que sean inapropiados, ilícitos, pornográficos, violentos, etc. AGILAR no se hace responsable del contenido de la Página Web, ni promueve, garantiza, supervisa ni recomienda los contenidos de ésta.</p>
                <li>7.-Responsabilidad.</li>
                  <p>El Usuario responderá de los daños y perjuicios de toda naturaleza que AGILAR pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones de estas condiciones de uso.<br/>
                  El Usuario se compromete a utilizar el sitio Web, de conformidad con la Ley, las presentes condiciones de uso, las buenas costumbres y el orden público. Del mismo modo, el Usuario se obliga a no utilizar el sitio Web, sus contenidos o servicios que se presten a través de ésta con fines o efectos ilícitos, siendo contrarios a estas condiciones, o lesivos de intereses o derechos de terceros, o que de cualquier otra forma, pueda dañar, inutilizar, hacer inaccesibles o deteriorar la Web, sus contenidos o impedir un normal uso de la misma por parte de otros usuarios.</p>
                <li>8.-Ley aplicable.</li>
                  <p className="pb-100">Las condiciones de uso se rigen en todos y cada uno de sus extremos por la legislación española. Para la resolución de cualquier controversia derivada de la interpretación o aplicación de los contenidos o servicios contemplados en el sitio Web, las partes acuerdan someterse expresamente a los juzgados y tribunales de la ciudad de Madrid, con renuncia a cualquier otro fuero general o especial que les pudiera corresponder.</p>
              </div>
        		</div>
        	</div>
        </div>

        <div className="footer-one fixed-bottom">
          <div className="container d-none">
            <div className="footer-content">
              <div className="row col-md-10 offset-md-1 footer-data pb-2">
                <div className="footer-item">
                  <h5>buenos aires</h5>
                  <p>Com. Clodomiro Urtubey 2327</p>
                  <p>1430 Buenos Aires (Argentina)</p>
                  <p>buenosaires@agilar.com </p>
                  <p>+54 11 4544 1912 </p>
                  <p>M-F from 8h to 17h</p>
                </div>
                <div className="footer-item gent-item">
                  <h5>gent</h5>
                  <p>Koning Albertlaan 198</p>
                  <p>9000 Gent (Bélgica)</p>
                  <p>gent@agilar.com</p>
                  <p>+32 9 335 4013</p>
                  <p>M-F from 9h to 17h</p>
                </div>
                <div className="footer-item">
                  <h5>madrid</h5>
                  <p>Avenida Manoteras, 32 </p>
                  <p>28050 Madrid (España)</p>
                  <p>madrid@agilar.com</p>
                  <p>+34 917 526 017</p>
                  <p>M-F from 9h to 17h</p>
                </div>
              </div>
              <div className="col-md-8 offset-md-2 social-box">
                <div className="col-md-12 social-content">
                  <div className="social-link">
                    <p>¡Síguenos en redes sociales para estar al tanto de nuestras novedades!</p>
                    <a href = "https://twitter.com/" target = "blank" >
                      <img src="/images/twitter.png" className="social-icons" alt="twitter"/>
                    </a>
                    <a href = "https://www.linkedin.com/" target = "blank" >
                      <img src="/images/linkdin.png" className="social-icons" alt="linkdin"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-main">
            <div className="row col-md-10 offset-md-1 footer-main-row">
              <ul className="list-inline">
                <li className="list-inline-item agilar-copyright">
                  &copy;{new Date().getFullYear()} - Agilar
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/terms`} >
                    {t('home.footer.conditions_of_use')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/legal-warning`} >
                    {t('home.footer.legal_notice')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/privacy-policy`} >
                    {t('home.footer.privacy_policy')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/cookies-policy`} >
                    {t('home.footer.cookies_policy')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('common')(Terms);
