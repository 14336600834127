import React from "react"
import {translate} from "react-i18next";
import Member from "./Member";
import {TEAM_MEMBERS_LIST} from "./MemberList";
import AgilarMemberList from "./AgilarMemberList";

const Team = ({t}) => {

    const members = TEAM_MEMBERS_LIST;

    const start = members.length - 1;
    for (let i = start; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = members[i]
        members[i] = members[j]
        members[j] = temp
    }

    // big screen
    const bigScreen = window.screen.width >= 1024;
    return (
        <div className="section section-6">
            <div className="container">
                <div className="col">
                    <h4 className="header text-uppercase agilar-yellow">We are commited</h4>
                    <p className="text-justify text-row">{t('about.team.copy')}</p>
                </div>
                {
                    bigScreen ?
                        <AgilarMemberList members={members}/>
                        :
                        <div className="row">
                            {members.map(member => <Member key={member.name}
                                                           member={member}
                                                           className="col-4 col-md-2"/>)}
                        </div>

                }
            </div>
        </div>
    )

}

export default translate('common')(Team);