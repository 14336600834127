import React from "react"

const useLocale = () => {
    const country = localStorage.getItem('country');
    const language = localStorage.getItem('language');

    return [country, language]
}

export default useLocale;
