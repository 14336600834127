import React, {Component} from "react"
import CourseDescription from "../CourseDescription";

const CoursePresentation = ({training}) => {
    const imagePath = training.image_url ? training.image_url : `/images/trainings/${training.category}.png`;
    return (
        <div className="row mx-0">
            <div className="col-md-12">
                <div className="description-box category_description">
                    <div className="description-head category_description">
                        <h4>{training.name}</h4>
                        <img src={imagePath} alt="TR"/>
                    </div>
                    <CourseDescription description={training.description}/>
                </div>
            </div>
        </div>
    )
}

export default CoursePresentation;
