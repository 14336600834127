import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table'

import { translate } from 'react-i18next';

class Policy extends Component {

  componentDidMount(){
    const { t } = this.props;
    document.title = "Agilar | "+ t('home.footer.cookies_policy');
  }

  render () {
    const i8n_language = localStorage.getItem('language') || 'es'
    const country = localStorage.getItem('country') || 'es'

    const { t } = this.props;
    return (
      <div>
      	<div className="terms-main">
        	<div className="terms-section">
        		<div className="container">
        			<div className="col-md-12">
                <h6 className="text-center">Política de cookies</h6>
              </div>
              <div className="col-md-10 offset-md-1">
                <p>En cumplimiento de la Ley 34/2002, de fecha 11 de julio, de servicios de la sociedad de la información y del comercio electrónico, de la Directiva 2009/136 CE de 12 de julio, siguiendo las directrices de la Agencia Española de Protección de Datos y de lo establecido en el nuevo Reglamento europeo de protección de datos 2016/679 CE, le informamos que en la web <a href = "# ">www.agilar.es</a> utilizamos cookies para facilitar la relación de los visitantes con nuestro contenido y para permitir elaborar estadísticas sobre las visitas recibidas, estas cookies pueden ser propias y de terceros, persistentes con la finalidad de mejorar el servicio prestado.</p>
                <p>La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas, permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.</p>
                <p>Sin su expreso consentimiento (mediante la activación de las cookies en su navegador) Agilar Spain S.L. en adelante AGILAR no enlazará en las cookies los datos memorizados con sus datos personales proporcionados en el momento del registro o la compra.</p>
                <h5>2.1 ¿Qué son las cookies?</h5>
                <p>Se denominan cookies a unos pequeños archivos que se graban en el navegador utilizado por cada visitante de nuestra web para que el servidor pueda recordar la visita de ese usuario con posterioridad cuando vuelva a acceder a nuestros contenidos. Las cookies permiten a una página web, entre otras, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo para, en función del tipo de navegación, poder ofrecer un mejor servicio a través de la página web. Esta información no revela la identidad del visitante, ni dato personal alguno, ni accede al contenido almacenado en su pc, pero sí que permite a nuestro sistema identificar a un usuario determinado que ya visitó la web con anterioridad, que visualizó determinadas páginas, etc. y además permite guardar las preferencias personales e información técnica como por ejemplo las visitas realizadas o páginas concretas que visite.</p>
                <p>Recopilamos ciertos datos de las cookies, que son datos almacenados directamente en el ordenador o dispositivo móvil que esté utilizando. Las cookies nos permiten recopilar información, como el tipo de navegador, el tiempo que pasa en los Servicios en línea, páginas visitadas, URL de referencia, preferencias de idioma y otros datos de tráfico agregados. Utilizamos la información con fines de seguridad, para facilitar la navegación, mostrar la información de manera más efectiva, recopilar información estadística, personalizar su experiencia mientras utiliza los Servicios en línea y reconocer su computadora para asistirle en el uso de los Servicios en línea. También recopilamos información estadística sobre el uso de los Servicios en línea para mejorar continuamente su diseño y funcionalidad, entender cómo se utilizan y ayudarnos a resolver problemas.</p>
                <p>Las cookies también nos permiten seleccionar cuáles de nuestros avisos publicitarios y ofertas podrían gustarle y mostrárselos cuando utilice los Servicios en línea o enviarle correos electrónicos de comercialización. También utilizamos cookies y otras tecnologías para rastrear respuestas a avisos publicitarios en línea y correos electrónicos de comercialización.</p>
                <h5>2.2. ¿Quién gestiona las cookies y tipo de cookies utilizadas en la página web?</h5>
                <p>Las cookies son gestionadas por el propio editor de la página web (Cookies propias) y se utilizan los siguientes tipos:</p>
                <ul>
                  <li>
                    - Cookies técnicas:
                  </li>
                  <p>Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.</p>
                  <li>
                    - Cookies de personalización
                  </li>
                  <p>Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serían el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.</p>
                  <li>
                    - Cookies de análisis
                  </li>
                  <p>Son aquellas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>
                  <li>
                    - Cookies de terceros:
                  </li>
                  <p>La Web <a href="# ">www.agilar.es</a> puede utilizar servicios de terceros que, por cuenta de AGILAR recopilaran información con fines estadísticos, de uso del sitio web por parte del usuario y para la prestación de otros servicios relacionados con la actividad del sitio web y otros servicios de Internet.</p>
                  <p>Puede elegir aceptar las cookies cambiando las opciones de su navegador o administrando sus preferencias de rastreo haciendo clic en “Preferencias de seguimiento” en la parte inferior de nuestra página de inicio</p>
                </ul>
                <h5>2.3. ¿Cookies de terceros y cuáles son sus finalidades?</h5>
                <p>Cookies analíticas de terceros: Son aquellas que permiten al responsable de las cookies, el seguimiento y análisis del comportamiento de los usuarios de los sitios web. Se utilizan para medir la actividad del sitio web y para crear perfiles de navegación de los usuarios, en cualquier caso la información tratada por el citado tercero siempre será con fines estadísticos no siendo posible identificar a los visitantes de la web.</p>
                <p>En particular, este sitio Web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc. con domicilio en los Estados Unidos con sede central en 1600 Amphitheatre Parkway, Mountain View, California 94043.  Para la prestación de estos servicios, estos utilizan cookies que recopilan la información, incluida la dirección IP del usuario, que será transmitida, tratada y almacenada por Google en los términos fijados en la Web Google.com. Incluyendo la posible transmisión de dicha información a terceros por razones de exigencia legal o cuando dichos terceros procesen la información por cuenta de Google.</p>
                <p>Si usted no desea que se guarden cookies en su navegador o prefiere recibir una información cada vez que una cookie solicite instalarse, puede configurar sus opciones de navegación para que se haga de esa forma.</p>
                <p>Respecto de las cookies de terceros, es decir aquellas que son ajenas a nuestro sitio web, no podemos hacernos responsables del contenido y veracidad de las políticas de privacidad que ellos incluyen por lo que la información que le ofrecemos es siempre con referencia a la fuente.</p>
                <Table className="table-privacy table-cookies">
                  <thead>
                    <tr>
                      <th>Cookie</th>
                      <th>Descripción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>_ga</td>
                      <td>De tipo HTTP, Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web</td>
                    </tr>
                    <tr>
                      <td>_gid</td>
                      <td>De tipo HTTP Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web</td>
                    </tr>
                    <tr>
                      <td>_icl</td>
                      <td>De tipo HTTP Registra el idioma utilizado </td>
                    </tr>
                    <tr>
                      <td>Consent</td>
                      <td>De tipo HTTP, Habilita los permisos para almacenar cookies en múltiples páginas web</td>
                    </tr>
                    <tr>
                      <td>SID<br/>SSID<br/>SIDCC<br/>NID<br/>SAPISID<br/>HSID</td>
                      <td>Estas cookies recogen información sobre las preferencias o intereses en productos y/o servicios recogida durante la navegación, con la finalidad de mostrar anuncios que se ajusten a las preferencias de los usuarios.</td>
                    </tr>
                    <tr>
                      <td>1P_JAR</td>
                      <td>De tipo HTTP de publicidad usada para registrar el movimiento del usuario e identificar sus propósitos t</td>
                    </tr>
                  </tbody>
                </Table>
                <h5>2.4 Permitir, bloquear o eliminar las cookies.</h5>
                <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador. El Usuario acepta expresamente, por la utilización de este Sitio web, el tratamiento de la información recabada en la forma y con los fines anteriormente mencionados. Y asimismo reconoce conocer la posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la selección de la configuración apropiada a tal fin en su navegador. Si bien esta opción de bloqueo de Cookies en su navegador puede no permitirle el uso pleno de todas las funcionalidades del Website, siguiendo las siguientes indicaciones, según el navegador que utilice:</p>
                <p>Firefox &nbsp;<a href="# ">https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros</a></p>
                <p>Chrome &nbsp;<a href="# ">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a></p>
                <p>Safari &nbsp;<a href="# ">https://support.apple.com/kb/ph21411?locale=es_ES</a></p>
                <p>Explorer &nbsp;<a href="# ">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a></p>
                <p className="pb-100">Si no desea que recopilemos datos con cookies, puede revisar la información sobrecómo controlar las cookies en &nbsp;<a href="# ">http://www.allaboutcookies.org/manage-cookes/index.htm.</a></p>
              </div>
        		</div>
        	</div>
        </div>
        <div className="footer-one fixed-bottom">
          <div className="container d-none">
            <div className="footer-content">
              <div className="row col-md-10 offset-md-1 footer-data">
                <div className="footer-item">
                  <h5>buenos aires</h5>
                  <p>Com. Clodomiro Urtubey 2327</p>
                  <p>1430 Buenos Aires (Argentina)</p>
                  <p>buenosaires@agilar.com </p>
                  <p>+54 11 4544 1912 </p>
                  <p>M-F from 8h to 17h</p>
                </div>
                <div className="footer-item gent-item">
                  <h5>gent</h5>
                  <p>Koning Albertlaan 198</p>
                  <p>9000 Gent (Bélgica)</p>
                  <p>gent@agilar.com</p>
                  <p>+32 9 335 4013</p>
                  <p>M-F from 9h to 17h</p>
                </div>
                <div className="footer-item">
                  <h5>madrid</h5>
                  <p>Avenida Manoteras, 32 </p>
                  <p>28050 Madrid (España)</p>
                  <p>madrid@agilar.com</p>
                  <p>+34 917 526 017</p>
                  <p>M-F from 9h to 17h</p>
                </div>
              </div>
              <div className="col-md-8 offset-md-2 social-box">
                <div className="col-md-12">
                  <div className="social-link">
                    <p>¡Síguenos en redes sociales para estar al tanto de nuestras novedades!</p>
                    <a href = "https://twitter.com/" target = "blank" >
                      <img src="/images/twitter.png" className="social-icons" alt="twitter"/>
                    </a>
                    <a href = "https://www.linkedin.com/" target = "blank" >
                      <img src="/images/linkdin.png" className="social-icons" alt="linkdin"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-main">
            <div className="row col-md-10 offset-md-1 footer-main-row">
              <ul className="list-inline">
                <li className="list-inline-item agilar-copyright">
                  &copy;{new Date().getFullYear()} - Agilar
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/terms`} >
                    {t('home.footer.conditions_of_use')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/legal-warning`} >
                    {t('home.footer.legal_notice')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/privacy-policy`} >
                    {t('home.footer.privacy_policy')}
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={`/${country}/${i8n_language}/cookies-policy`} >
                    {t('home.footer.cookies_policy')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('common')(Policy);
