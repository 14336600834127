export const handleServerError = (historyInstance, error, country, language) => {
    switch (error.response.status) {
        case 404:
            historyInstance.push(`/${country}/${language}/not-found`);
            return;
        case 403:
            historyInstance.push(`/${country}/${language}/forbidden`);
            return;
        default:
            historyInstance.push(`/${country}/${language}/server-error`);
            return;
    }
}
