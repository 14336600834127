import React from "react"
import {translate} from "react-i18next";
import {Link} from "react-router-dom";


const Category = ({category, country, language, t}) => {
    const name = category.name ? category.name : category.abbrev_name

    const title = category[`title_${language}`] ? category[`title_${language}`] : t(`services.trainings.${name}.title`)
    const description = category[`detail_${language}`] ? category[`detail_${language}`] : t(`services.trainings.${name}.detail`)
    const imagePath = category.image_url ? category.image_url : `/images/trainings/${name}.png`;

    const asteriskKey = `services.trainings.${name}.asterisk`
    const asteriskText = t(asteriskKey)

    const asteriskExists =  asteriskText !== asteriskKey

    return (
        <div className="col-md-4">
            <div className="training-data">
                <h4 className="training-heading">
                    <span className="border-content">{title.substring(0, 3)}</span>{title.substring(3, title.length)}
                </h4>
                <div className="image-container">
                    <img src={imagePath} className="img-responsive training-img" alt={title}/>
                </div>
                <p className="training-content">{description} {asteriskExists && <><br/>* {asteriskText}</> }</p>
                <div className="d-flex flex-grow-1"/>
                <Link to={`/${country}/${language}/trainings/${name}`}>
                    {t('services.trainings_index.more_info')}&nbsp;>
                </Link>
                <Link style={{position: 'relative'}} to={`/${country}/${language}/courses?category=${name}`}
                      className="btn yellow-btn text-uppercase">
                    {t('services.trainings_index.upcoming_courses')}
                </Link>
            </div>
        </div>
    )
};

export default translate('common')(Category);

