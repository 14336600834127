import React, { Component } from 'react';

export default class Consulting extends Component {
  componentDidMount(){
	  this.props.setActiveMenu('services')
  }
  render () {
    return (
      <div className="training-main">
      	<div className="training-section">
      		<div className="container">
      			<div className="col-md-12 training-row">
      				<div className="row">
      					<div className="col-md-4">
      						<div className="training-data">
      							<h4 className="training-heading"><span className="border-content">CERT</span>IFIED SCRUM MASTER®</h4>
      							<img src="/images/training1.png" className="img-responsive training-img" alt="TR" />
      							<p className="training-content">Become a Certified ScrumMaster®! Learn the essentials of Scrum: values and principles, roles and practices, and how to run a Scrum project. </p>
      							<a className="training-link" href="# ">More info&nbsp;> </a>
      							<button className="btn yellow-btn text-uppercase" type="submit">upcoming courses</button>
      						</div>
      					</div>
      					<div className="col-md-4">
      						<div className="training-data">
      							<h4 className="training-heading"><span className="border-content">CERT</span>IFIED SCRUM PRODUCT OWNER®</h4>
      							<img src="/images/training2.png" className="img-responsive training-img" alt="TR" />
      							<p className="training-content">Expand your knowledge on Agile/Scrum product development and project management with this Certified Scrum Product Owner® training.</p>
      							<a className="training-link" href="# ">More info&nbsp;> </a>
      							<button className="btn yellow-btn text-uppercase" type="submit">upcoming courses</button>
      						</div>
      					</div>
      					<div className="col-md-4">
      						<div className="training-data">
      							<h4 className="training-heading"><span className="border-content">CERT</span>IFIED SCRUM DEVELOPER®</h4>
      							<img src="/images/training3.png" className="img-responsive training-img" alt="TR" />
      							<p className="training-content">Develop a real application using the Scrum framework combining theory with practical exercises, supported by the trainer.</p>
      							<a className="training-link" href="# ">More info&nbsp;> </a>
      							<button className="btn yellow-btn text-uppercase" type="submit">upcoming courses</button>
      						</div>
      					</div>
      				</div>
      			</div>
      			<div className="col-md-12 training-row">
      				<div className="row">
      					<div className="col-md-4">
      						<div className="training-data">
      							<h4 className="training-heading"><span className="border-content">INTR</span>ODUCTION TO SCRUM</h4>
      							<p className="training-content">Learn the basics of Scrum and how it can help organizations to become more effective and efficient in this non-certified 1 day course. </p>
      							<a className="training-link" href="# ">More info&nbsp;> </a>
      							<button className="btn yellow-btn text-uppercase" type="submit">upcoming courses</button>
      						</div>
      					</div>
      					<div className="col-md-4">
      						<div className="training-data">
      							<h4 className="training-heading"><span className="border-content">SCRU</span>M IN A NUTSHELL</h4>
      							<p className="training-content">This half-day course is the perfect way to come into contact with agile methodologies and the world of Scrum.</p>
      							<a className="training-link" href="# ">More info&nbsp;> </a>
      							<button className="btn yellow-btn text-uppercase" type="submit">upcoming courses</button>
      						</div>
      					</div>
      					<div className="col-md-4">
      						<div className="training-data">
      							<h4 className="training-heading"><span className="border-content">AGILE</span> AND SCRUM</h4>
      							<p className="training-content">Learn the essentials of Scrum: values, principles, roles and practices, and get prepared to apply Scrum in your professional projects.</p>
      							<a className="training-link" href="# ">More info&nbsp;> </a>
      							<button className="btn yellow-btn text-uppercase" type="submit">upcoming courses</button>
      						</div>
      					</div>
      				</div>
      			</div>
      		</div>
      	</div>
      </div>
    )

  }


}
