import React, {Component} from 'react';
import {translate} from 'react-i18next';
import './index.css';
import CookieConsent from "react-cookie-consent";

class CookieBanner extends Component {

  render (){
    const country = localStorage.getItem('country') || 'es'
    const { t } = this.props;

    return(
      <CookieConsent
          location="bottom"
          buttonText= {t('home.cookie_banner.accept')}
          buttonClasses="yellow-btn cookies-btn"
          declineButtonClasses="close-btn"
          declineButtonText="x"
          flipButtons={true}
          enableDeclineButton={true}
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          setDeclineCookie={false}
      >
      <div className="container px-0">
        <div className="col-md-10 offset-md-1">
          <div className="cookies-box">
            <div className="cookies-content">
              <h4>{t('home.cookie_banner.introduction')}</h4>
              <p>{t('home.cookie_banner.description')}</p>
            </div>
          </div>
        </div>
      </div>
      </CookieConsent>
    )
  }
}

export default translate('common')(CookieBanner);
