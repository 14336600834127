import React from "react"
import {useField} from "formik";


const CheckboxWrapper = ({children, containerClass, ...props}) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <div className={containerClass}>
            <label className="checkbox">
                <input type="checkbox" className="mr-2" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
}

export default CheckboxWrapper
