import React, {useState, useEffect} from "react"
import useLocale from "../../componentLibrary/hooks/useLocale";
import axios from "axios";
import {handleServerError} from "../../utils/request";
import {withRouter} from "react-router-dom";
import {translate} from "react-i18next";
import CategoryCourses from "../../components/courses/CategoryCourses";

const CategoryTrainingsContainer = ({setActiveMenu, t, location, history}) => {
    const [loading, setLoading] = useState(true)
    const [trainings, setTrainings] = useState([null])
    const [country, language] = useLocale();
    const [category, setCategory] = useState(null)


    useEffect(() => {
        setActiveMenu('services');
        document.title = "Agilar | " + t('home.nav.courses');
        const query = new URLSearchParams(location.search);
        const category = query.get('category')
        setCategory(category)
        const params = `language=${language}&country=${country}${category ? `&category=${category}` : ''}`;

        axios.get(`${process.env.REACT_APP_API_URL}/api/v2/courses?${params}`)
            .then(response => {
                setTrainings(response.data)
                setLoading(false)
            })
            .catch(error => handleServerError(history, error, country, language))
    }, [])

    return (
        <CategoryCourses trainings={trainings} loading={loading} category={category}/>
    )
}

export default translate('common')(withRouter(CategoryTrainingsContainer));
