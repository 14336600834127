export const TEAM_MEMBERS_LIST = [
    {
        name: 'xavier',
        firstName: 'Xavier',
        lastName: 'Quesada',
        jobTitle: 'Managing Partner, CST & CEC',
        linkedin: 'https://www.linkedin.com/in/xquesada/'
    },
    {
        name: 'tiago',
        firstName: 'Tiago',
        lastName: 'Garcez',
        jobTitle: 'Managing Partner, CST & CPC',
        linkedin: 'https://www.linkedin.com/in/tiago-garcez-575292/'
    },
    {
        name: 'joke',
        firstName: 'Joke',
        lastName: 'Vandemaele',
        jobTitle: 'Agile Coach and CST',
        linkedin: 'https://www.linkedin.com/in/jokevandemaele/'
    },
    {
        name: 'diana',
        firstName: 'Diana',
        lastName: 'Naval',
        jobTitle: 'Managing Director',
        linkedin: 'https://www.linkedin.com/in/diananaval/'
    },
    {
        name: 'diego',
        firstName: 'Diego',
        lastName: 'Quesada',
        jobTitle: 'Agile Coach & CSP',
        linkedin: 'https://www.linkedin.com/in/diegoqa/'
    },
    {
        name: 'rauk',
        firstName: 'Raúl',
        lastName: 'Herranz',
        jobTitle: 'Agile Coach, Trainer & CSP',
        linkedin: 'https://www.linkedin.com/in/raulherranz/'
    },
    {
        name: 'rico',
        firstName: 'Rico',
        lastName: 'Trevisan',
        jobTitle: 'Agile Coach & Trainer',
        linkedin: 'https://www.linkedin.com/in/ricotrevisan/'
    },
    {
        name: 'felipe',
        firstName: 'Felipe',
        lastName: 'Fernandes',
        jobTitle: 'Consultant',
        linkedin: 'https://www.linkedin.com/in/-felipefernandes/'
    },
    {
        name: 'laura',
        firstName: 'Laura',
        lastName: 'Cuatrecasas',
        jobTitle: 'Marketing & Communication Lead',
        linkedin: 'https://www.linkedin.com/in/lauracuatrecasas/'
    },
    {
        name: 'mariajose',
        firstName: 'Maria José',
        lastName: 'Fonseca',
        jobTitle: 'Consultant',
        linkedin: 'https://www.linkedin.com/in/mar%C3%ADa-jos%C3%A9-fonseca1/'
    },
    {
        name: 'alberto',
        firstName: 'Alberto',
        lastName: 'Gómez',
        jobTitle: 'Agile Coach',
        linkedin: 'https://www.linkedin.com/in/agomezagil/'
    },
    {
        name: 'natalia',
        firstName: 'Natalia',
        lastName: 'Carrillo',
        jobTitle: 'Office Manager Buenos Aires',
        linkedin: 'https://www.linkedin.com/in/natalia-carrillo-bernal-2471328/'
    },
    {
        name: 'itziar',
        firstName: 'Itziar',
        lastName: 'Herreros',
        jobTitle: 'Office Manager Madrid',
        linkedin: 'https://www.linkedin.com/in/itziar-herreros-mart%C3%ADn-5937526b/'
    },
    {
        name: 'araceli',
        firstName: 'Araceli',
        lastName: 'González',
        jobTitle: 'Office Manager Barcelona',
        linkedin: 'https://www.linkedin.com/in/araceli-geraldine-gonzalez-poyeton-11490b116/'
    },
    {
        name: 'clara',
        firstName: 'Clara',
        lastName: 'Landrito',
        jobTitle: 'Office Manager Brussels',
        linkedin: 'https://www.linkedin.com/in/clarence-landrito-ecabeza-a8161010b/'
    },
    {
        name: 'nico',
        firstName: 'Nicolás',
        lastName: 'Chirio',
        jobTitle: 'Scrum Master',
        linkedin: 'https://www.linkedin.com/in/nicol%C3%A1s-chirio-72b977105/'
    },
    {
        name: 'wouter',
        firstName: 'Wouter',
        lastName: 'Crets',
        jobTitle: 'Consultant',
        linkedin: 'https://www.linkedin.com/in/wouter-crets-93b7191a/'
    },
    {
        name: 'loeiz',
        firstName: 'Loeiz',
        lastName: 'Cadiou',
        jobTitle: 'Consultant',
        linkedin: 'https://www.linkedin.com/in/loeiz-c-a339472/'
    },
]

const shuffle = () => {
    // shuffle array of members using the Fisher-Yates algorithm
    const start = TEAM_MEMBERS_LIST.length - 1;
    const membersCopy = [...TEAM_MEMBERS_LIST]
    for(let i = start; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = membersCopy[i]
        membersCopy[i] = membersCopy[j]
        membersCopy[j] = temp
    }
    return membersCopy
}

export const members = () => {
    return shuffle();
}