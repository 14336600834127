import React from "react"
import classes from "./FloatingLabelInput.module.css"
import ContactTextarea from "../ContactTextarea";

const FloatingLabelTextArea = ({id, disabled, name, className, isRequired, required, onChange, label, value}) => {
    return (
        <>
            <ContactTextarea
                id={id}
                name={name}
                className={`${classes.agilarInput} ${className} ${isRequired ? classes.isRequired : ''}`}
                onChange={onChange}
                placeholder="placeholder"
                value={value}
                disabled={disabled}
                required={required}/>
            <label htmlFor={id} className={classes.agilarLabel}>{label}</label>
        </>

    )
}

export default FloatingLabelTextArea
