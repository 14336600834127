import React from "react";
import {translate} from "react-i18next";

const Offices = ({t}) => {
    return (
        <div className="history-tab">
            <h4 className="header">{t('about.history.offices.header')}</h4>
            <p>{t('about.history.offices.line1')}</p>
            <p>{t('about.history.offices.line2')}</p>
            <p>{t('about.history.offices.line3')}</p>
        </div>
    )
}

export default translate('common')(Offices);